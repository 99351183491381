<template>
    <el-card class="page-container">
        <template #header>
            <div class="header">
                <span>美团招商资质登记</span>
                <div class="extra">
                    <el-button type="primary" @click="openDrawer()">添加新资质</el-button>
                    <el-upload class="upload-excel" action="/api/mtshop/excelShopUpload" :before-upload="beforeUpload"
                               :headers="headers" :show-file-list="false" accept=".xlsx, .xls">
                      <el-button size="small" type="primary">点击上传Excel</el-button>
                    </el-upload>
                </div>
            </div>
        </template>
        <!-- 搜索表单 -->
        <el-form inline>
            <div id="search" :model="shopQueryDTO">
                <el-form-item label="执照名：">
                    <el-input v-model="shopQueryDTO.shopName" class="inputBar" clearable @keyup.enter="enterFunction">
                    </el-input>
                </el-form-item>
                <el-form-item label="登记日期：">
                    <el-date-picker
                        v-model="shopQueryDTO.operationDate"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="招商日期：">
                    <el-date-picker
                        v-model="shopQueryDTO.createDate"
                        type="daterange"
                        align="right"
                        unlink-panels
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        clearable
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="审核状态">
                    <el-select v-model="shopQueryDTO.examine" placeholder="请选择" style="width: 100px;" @blur="orderList()">
                        <el-option label="待提交" value="待提交"></el-option>
                        <el-option label="待审核" value="待审核"></el-option>
                        <el-option label="审核通过" value="审核通过"></el-option>
                        <el-option label="审核驳回" value="审核驳回"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="营业状态">
                    <el-select v-model="shopQueryDTO.tradeStatus" placeholder="请选择" style="width: 100px;" @blur="orderList()">
                        <el-option label="营业中" value="营业中"></el-option>
                        <el-option label="休息中" value="休息中"></el-option>
                    </el-select>
                </el-form-item>
            </div>
        </el-form>
        <el-button type="success" class="button" @click="downloadShopExcel()">导出门店</el-button>
        <el-button type="primary" class="button" @click="shopList()">搜索</el-button>
        <el-button class="button" @click="clear()" >重置</el-button>
        <el-table :data="shopInfo" border style="width: 100%; height: 460px;"
                    :header-cell-style="{backgroundColor: 'rgb(239 239 239)',height: '10%'}"
                    :cell-style="{fontSize: 'xx-small'}"
                    :row-style="rowStyle"
                    v-loading="loading"
                    >
            <el-table-column label="门店编号" prop="storeCode" fixed width="90"></el-table-column>
            <el-table-column label="门店名称" prop="storeName"> </el-table-column>
            <el-table-column label="省" prop="province" width="70"></el-table-column>
            <el-table-column label="登记日期" prop="operationDate" width="100"></el-table-column>
            <el-table-column label="执照名" width="320">
                    <template #default="scope">
                        <el-tooltip class="item" effect="dark" :content=scope.row.shopName placement="top-start">
                            {{ scope.row.shopNameOmit }}
                        </el-tooltip>
                    </template>
            </el-table-column>
            <el-table-column label="招商日期" prop="createDate" width="100"></el-table-column>
            <el-table-column label="审核状态" prop="examineView" width="81"></el-table-column>
            <el-table-column label="营业状态" prop="tradeStatusView" width="81"></el-table-column>
            <el-table-column label="操作" width="110" fixed="right">
                <template #default="{ row }">
                    <el-link :underline="false" type="success" @click="shopUpdate(row)">
                        修改
                    </el-link>
                    <!-- <el-link :underline="false" type="danger" @click="shopDel(row)" slot="reference"> -->
                    <el-link :underline="false" type="danger" @click="open(row)" slot="reference">
                        删除
                    </el-link>
                </template>
            </el-table-column>
            <template #empty>
                <el-empty description="没有数据" />
            </template>
        </el-table>
        <el-pagination
            v-model:current-page="shopQueryDTO.nowPage"
            v-model:page-size="shopQueryDTO.pageSize"
            layout="total, prev, pager, next, sizes"
            :page-sizes="[20, 40, 60]"
            :page-size="20"
            background
            :total="total"
            @size-change="onSizeChange"
            @current-change="onCurrentChange"
            style="justify-content: flex-end;
            position: fixed;
            bottom: 45px;
            width: 80%;"
        />

        <shopUpd ref="tableChangeShow" :updData="updData"></shopUpd>
        <shopOperate ref="tablePlusShow" :updData="updData" @shop-operated="handleShopOperated"></shopOperate>
        <shopAdd ref="tableShow" :updData="updData"></shopAdd>
        <shopMapMessage ref="tableMapShow"></shopMapMessage>
    </el-card>
</template>

<script setup>
import { ref,defineProps } from 'vue'
import {
    shopListService,
    shopAccount,
    shopDelService
 } from '@/api/mtshop.js';
import shopAdd from './shopAdd.vue';
import shopUpd from './shopUpd.vue';
import axios from 'axios'
import shopMapMessage from './shopMapMessage.vue'
import shopOperate from './shopOperate.vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import {useTokenStore} from "@/store/token";
// const fullscreenLoading = ref(false)

const headers = {
  Authorization: useTokenStore().getToken()
}

const props = defineProps ({
    userInfo : String
})

const tableShow=ref()
const openDrawer=()=>{
    tableShow.value.open()
}
const gdMapExport=()=>{
    tableMapShow.value.open()
}

const beforeUpload = (file) => {
  const tokenStore = useTokenStore();
  console.log(useTokenStore().getToken());
  const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel';
  // const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isExcel) {
    ElMessage.error('上传的文件不是Excel!')
  }
  return isExcel;
};


const shopQueryDTO=ref({
    account: "",
    shopName: "",
    merchants: "",
    checkStatus: "",
    operationDate: "",
    createDate: "",
    operationDateStart: "",
    operationDateEnd: "",
    createDateStart: "",
    createDateEnd: "",
    examine: "",
    startStatus: "",
    tradeStatus: "",
    nowPage: 1,
    pageSize: 20
})

const handleShopOperated=(payload)=>{
    // console.log('子组件触发了事件', payload);
    shopList()
}

const clear=()=>{
    shopQueryDTO.value.account='';
    shopQueryDTO.value.shopName='';
    shopQueryDTO.value.merchants='';
    shopQueryDTO.value.checkStatus='';
    shopQueryDTO.value.operationDate='';
    shopQueryDTO.value.createDate='';
    shopQueryDTO.value.operationDateStart='';
    shopQueryDTO.value.operationDateEnd='';
    shopQueryDTO.value.createDateStart='';
    shopQueryDTO.value.createDateEnd='';
    shopQueryDTO.value.examine='';
    shopQueryDTO.value.startStatus='';
    shopQueryDTO.value.tradeStatus='';

    shopList()
}

const total = ref(null)//总条数

const shopInfo=ref([
    {
    id: "",
    creditCode: "",
    shopName: "",
    shopNameOmit: "",
    shopAddress: "",
    shopArea: "",
    corporate: "",
    corPhone: "",
    createDate: "",
    shopAccount: "",
    merchants: "",
    account: "",
    storeCode: "",
    storeName: "",
    storeArea: "",
    examine: "",
    startStatus: "",
    tradeStatus: "",
    checkStatus: "",
    examineView: '',
    startStatusView: '',
    tradeStatusView: '',
    available: '',
    availableResult: '',
    availableOmit: ''
    }
])
const userInfo=ref({
  username:'',
  realname:'',
  createTime:'',
  avatar:'',
  departName:'',
  departCode:'',
  delFlag:''
})

const tablePlusShow=ref()
const tableChangeShow=ref()
const tableMapShow=ref()
const loading = ref(true)

const updData=ref({
    updId:'',
    departCode:'',
    accountList:[],
    CNAddress:[]
})

const shopUpdate=(row)=>{
    updData.value.updId=row.id
    updData.value.departCode=userInfo.value.departCode
    tableChangeShow.value.open()
}

const shopPlus=(row)=>{
    updData.value.updId=row.id
    tablePlusShow.value.open()
}

const shopList = async()=>{
    loading.value=true
    shopQueryDTO.value.account.trim();
    shopQueryDTO.value.shopName.trim();
    shopQueryDTO.value.checkStatus.trim();
    shopQueryDTO.value.merchants.trim();


    var dateStringList = shopQueryDTO.value.operationDate;
    if(dateStringList!=null && dateStringList!=''){
        var startTime=dateStringList[0]
        var endTime=dateStringList[1]
        var operationDateStart=formatDate(startTime);
        var operationDateEnd=formatDate(endTime);
        shopQueryDTO.value.operationDateStart=operationDateStart
        shopQueryDTO.value.operationDateEnd=operationDateEnd
    }


    var dateStringList2 = shopQueryDTO.value.createDate;
    if(dateStringList2!=null && dateStringList2!=''){
        var startTime2=dateStringList2[0]
        var endTime2=dateStringList2[1]
        var createDateStart=formatDate(startTime2);
        var createDateEnd=formatDate(endTime2);
        shopQueryDTO.value.createDateStart=createDateStart
        shopQueryDTO.value.createDateEnd=createDateEnd
    }

    let result=await shopListService(shopQueryDTO.value);
    shopInfo.value=result.data.records;
    total.value=result.data.total;
    shopInfo.value.forEach((num)=>{
        var str=String(num.availableResult)
        if(str.length>8){
            num.availableOmit=str.substring(0,8)+"..."
        }else{
            num.availableOmit=str.substring(0,8)
        }
    })

    shopInfo.value.forEach((num)=>{
        var str=String(num.shopName)
        if(str.length>5){
            num.shopNameOmit=str.substring(0,7)+"..."
        }else{
            num.shopNameOmit=str.substring(0,7)
        }
    })
    loading.value=false
}
shopList();

const accountListInit = async()=>{
    const result=await shopAccount();
    updData.value.accountList=result.data
}
accountListInit();


const downloadShopExcel = async () => {
  loading.value=true
  const tokenStore = useTokenStore();
  try {
    // 假设这是你的文件URL
    loading.value=true
    shopQueryDTO.value.account.trim();
    shopQueryDTO.value.shopName.trim();
    shopQueryDTO.value.checkStatus.trim();
    shopQueryDTO.value.merchants.trim();
    var dateStringList = shopQueryDTO.value.operationDate;
    if(dateStringList!=null && dateStringList!=''){
      var startTime=dateStringList[0]
      var endTime=dateStringList[1]
      var operationDateStart=formatDate(startTime);
      var operationDateEnd=formatDate(endTime);
      shopQueryDTO.value.operationDateStart=operationDateStart
      shopQueryDTO.value.operationDateEnd=operationDateEnd
    }
    var dateStringList2 = shopQueryDTO.value.createDate;
    if(dateStringList2!=null && dateStringList2!=''){
      var startTime2=dateStringList2[0]
      var endTime2=dateStringList2[1]
      var createDateStart=formatDate(startTime2);
      var createDateEnd=formatDate(endTime2);
      shopQueryDTO.value.createDateStart=createDateStart
      shopQueryDTO.value.createDateEnd=createDateEnd
    }

    let baseUrl = "/api/mtshop/downloadShopExcel"
    // 使用axios发送GET请求
    const response = await axios({
      method: 'post',
      url: baseUrl,
      data:shopQueryDTO.value,
      headers: {
        'Authorization': tokenStore.getToken()
      },
      responseType: 'blob', // 重要：指示axios返回Blob对象
    });

    loading.value = false
    // 创建一个Blob对象
    const blob = new Blob([response.data], { type: response.headers['content-type'] });

    // 创建一个指向Blob对象的URL
    const url = window.URL.createObjectURL(blob);

    // 创建一个a标签用于下载
    const link = document.createElement('a');
    link.href = url;
    // 设置文件名（可选，如果服务器没有提供）
    link.setAttribute('download', '门店数据.xlsx');

    // 触发点击事件
    document.body.appendChild(link);
    link.click();

    // 清理（可选）
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  } catch (error) {
    loading.value = false
    console.error('下载文件时出错:', error);
  }
}


const enterFunction = () => {
    // if(shopQueryDTO.value.account!=''||shopQueryDTO.value.shopName!=''||
    //    shopQueryDTO.value.merchants!=''||shopQueryDTO.value.checkStatus!=''
    // ){
        shopList()
    // }
  // 在这里编写按下回车键后想要执行的代码
}

const shopDel= async(row)=>{
    await shopDelService(row.id)
    ElMessage.success('数据已删除')
    shopList();
}

const rowStyle = ({ row, rowIndex, column, columnIndex })=>{
    if (row.available === 2) {
        return {
            'background-color': '#ff9dae'
        };
    }
    return {};
}

const open = (row)=>{
    ElMessageBox.confirm('这将永久删除该资质，确认要这么做吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
    }).then(() => {
        shopDel(row)
    })
}

//分页条数据模型

//当每页条数发生了变化，调用此函数
const onSizeChange = (size) => {
    shopQueryDTO.value.pageSize = size
    shopList();
}
//当前页码发生变化，调用此函数
const onCurrentChange = (num) => {
    shopQueryDTO.value.nowPage = num
    shopList();
}

const formatDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    // JavaScript 月份是从 0 开始的，所以需要加 1
    var month = ("0" + (date.getMonth() + 1)).slice(-2);

    // 获取日期，如果小于 10 则前面补 0
    var day = ("0" + date.getDate()).slice(-2);

    // 获取小时（24小时制），如果小于 10 则前面补 0
    var hours = ("0" + date.getHours()).slice(-2);

    // 获取分钟，如果小于 10 则前面补 0
    var minutes = ("0" + date.getMinutes()).slice(-2);

    // 获取秒数，如果小于 10 则前面补 0
    var seconds = ("0" + date.getSeconds()).slice(-2);

    // 拼接成目标格式
    var string=year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    return string
}

const Caddress=ref([
{"address":"北京-东城区"},
{"address":"北京-西城区"},
{"address":"北京-朝阳区"},
{"address":"北京-丰台区"},
{"address":"北京-石景山区"},
{"address":"北京-海淀区"},
{"address":"北京-门头沟区"},
{"address":"北京-房山区"},
{"address":"北京-通州区"},
{"address":"北京-顺义区"},
{"address":"北京-昌平区"},
{"address":"北京-大兴区"},
{"address":"北京-怀柔区"},
{"address":"北京-平谷区"},
{"address":"北京-密云区"},
{"address":"北京-延庆区"},
{"address":"天津-和平区"},
{"address":"天津-河东区"},
{"address":"天津-河西区"},
{"address":"天津-南开区"},
{"address":"天津-河北区"},
{"address":"天津-红桥区"},
{"address":"天津-东丽区"},
{"address":"天津-西青区"},
{"address":"天津-津南区"},
{"address":"天津-北辰区"},
{"address":"天津-武清区"},
{"address":"天津-宝坻区"},
{"address":"天津-滨海新区"},
{"address":"天津-宁河区"},
{"address":"天津-静海区"},
{"address":"天津-蓟州区"},
{"address":"河北-石家庄-长安区"},
{"address":"河北-石家庄-桥西区"},
{"address":"河北-石家庄-新华区"},
{"address":"河北-石家庄-井陉矿区"},
{"address":"河北-石家庄-裕华区"},
{"address":"河北-石家庄-藁城区"},
{"address":"河北-石家庄-鹿泉区"},
{"address":"河北-石家庄-栾城区"},
{"address":"河北-石家庄-井陉县"},
{"address":"河北-石家庄-正定县"},
{"address":"河北-石家庄-行唐县"},
{"address":"河北-石家庄-灵寿县"},
{"address":"河北-石家庄-高邑县"},
{"address":"河北-石家庄-深泽县"},
{"address":"河北-石家庄-赞皇县"},
{"address":"河北-石家庄-无极县"},
{"address":"河北-石家庄-平山县"},
{"address":"河北-石家庄-元氏县"},
{"address":"河北-石家庄-赵县"},
{"address":"河北-石家庄-辛集市"},
{"address":"河北-石家庄-晋州市"},
{"address":"河北-石家庄-新乐市"},
{"address":"河北-唐山-路南区"},
{"address":"河北-唐山-路北区"},
{"address":"河北-唐山-古冶区"},
{"address":"河北-唐山-开平区"},
{"address":"河北-唐山-丰南区"},
{"address":"河北-唐山-丰润区"},
{"address":"河北-唐山-曹妃甸区"},
{"address":"河北-唐山-滦南县"},
{"address":"河北-唐山-乐亭县"},
{"address":"河北-唐山-迁西县"},
{"address":"河北-唐山-玉田县"},
{"address":"河北-唐山-遵化市"},
{"address":"河北-唐山-迁安市"},
{"address":"河北-唐山-滦州市"},
{"address":"河北-秦皇岛-海港区"},
{"address":"河北-秦皇岛-山海关区"},
{"address":"河北-秦皇岛-北戴河区"},
{"address":"河北-秦皇岛-抚宁区"},
{"address":"河北-秦皇岛-满族自治县"},
{"address":"河北-秦皇岛-昌黎县"},
{"address":"河北-秦皇岛-卢龙县"},
{"address":"河北-邯郸-邯山区"},
{"address":"河北-邯郸-丛台区"},
{"address":"河北-邯郸-复兴区"},
{"address":"河北-邯郸-峰峰矿区"},
{"address":"河北-邯郸-肥乡区"},
{"address":"河北-邯郸-永年区"},
{"address":"河北-邯郸-临漳县"},
{"address":"河北-邯郸-成安县"},
{"address":"河北-邯郸-大名县"},
{"address":"河北-邯郸-涉县"},
{"address":"河北-邯郸-磁县"},
{"address":"河北-邯郸-邱县"},
{"address":"河北-邯郸-鸡泽县"},
{"address":"河北-邯郸-广平县"},
{"address":"河北-邯郸-馆陶县"},
{"address":"河北-邯郸-魏县"},
{"address":"河北-邯郸-曲周县"},
{"address":"河北-邯郸-武安市"},
{"address":"河北-邢台-襄都区"},
{"address":"河北-邢台-信都区"},
{"address":"河北-邢台-任泽区"},
{"address":"河北-邢台-南和区"},
{"address":"河北-邢台-临城县"},
{"address":"河北-邢台-内丘县"},
{"address":"河北-邢台-柏乡县"},
{"address":"河北-邢台-隆尧县"},
{"address":"河北-邢台-宁晋县"},
{"address":"河北-邢台-巨鹿县"},
{"address":"河北-邢台-新河县"},
{"address":"河北-邢台-广宗县"},
{"address":"河北-邢台-平乡县"},
{"address":"河北-邢台-威县"},
{"address":"河北-邢台-清河县"},
{"address":"河北-邢台-临西县"},
{"address":"河北-邢台-南宫市"},
{"address":"河北-邢台-沙河市"},
{"address":"河北-保定-竞秀区"},
{"address":"河北-保定-莲池区"},
{"address":"河北-保定-满城区"},
{"address":"河北-保定-清苑区"},
{"address":"河北-保定-徐水区"},
{"address":"河北-保定-涞水县"},
{"address":"河北-保定-阜平县"},
{"address":"河北-保定-定兴县"},
{"address":"河北-保定-唐县"},
{"address":"河北-保定-高阳县"},
{"address":"河北-保定-容城县"},
{"address":"河北-保定-涞源县"},
{"address":"河北-保定-望都县"},
{"address":"河北-保定-安新县"},
{"address":"河北-保定-易县"},
{"address":"河北-保定-曲阳县"},
{"address":"河北-保定-蠡县"},
{"address":"河北-保定-顺平县"},
{"address":"河北-保定-博野县"},
{"address":"河北-保定-雄县"},
{"address":"河北-保定-涿州市"},
{"address":"河北-保定-定州市"},
{"address":"河北-保定-安国市"},
{"address":"河北-保定-高碑店市"},
{"address":"河北-张家口-桥东区"},
{"address":"河北-张家口-桥西区"},
{"address":"河北-张家口-宣化区"},
{"address":"河北-张家口-下花园区"},
{"address":"河北-张家口-万全区"},
{"address":"河北-张家口-崇礼区"},
{"address":"河北-张家口-张北县"},
{"address":"河北-张家口-康保县"},
{"address":"河北-张家口-沽源县"},
{"address":"河北-张家口-尚义县"},
{"address":"河北-张家口-蔚县"},
{"address":"河北-张家口-阳原县"},
{"address":"河北-张家口-怀安县"},
{"address":"河北-张家口-怀来县"},
{"address":"河北-张家口-涿鹿县"},
{"address":"河北-张家口-赤城县"},
{"address":"河北-承德市-双桥区"},
{"address":"河北-承德市-双滦区"},
{"address":"河北-承德市-手营子矿区"},
{"address":"河北-承德市-承德县"},
{"address":"河北-承德市-兴隆县"},
{"address":"河北-承德市-滦平县"},
{"address":"河北-承德市-隆化县"},
{"address":"河北-承德市-满族自治县"},
{"address":"河北-承德市-古族自治县"},
{"address":"河北-承德市-平泉市"},
{"address":"河北-沧州-新华区"},
{"address":"河北-沧州-运河区"},
{"address":"河北-沧州-沧县"},
{"address":"河北-沧州-青县"},
{"address":"河北-沧州-东光县"},
{"address":"河北-沧州-海兴县"},
{"address":"河北-沧州-盐山县"},
{"address":"河北-沧州-肃宁县"},
{"address":"河北-沧州-南皮县"},
{"address":"河北-沧州-吴桥县"},
{"address":"河北-沧州-献县"},
{"address":"河北-沧州-回族自治县"},
{"address":"河北-沧州-泊头市"},
{"address":"河北-沧州-任丘市"},
{"address":"河北-沧州-黄骅市"},
{"address":"河北-沧州-河间市"},
{"address":"河北-廊坊-安次区"},
{"address":"河北-廊坊-广阳区"},
{"address":"河北-廊坊-固安县"},
{"address":"河北-廊坊-永清县"},
{"address":"河北-廊坊-香河县"},
{"address":"河北-廊坊-大城县"},
{"address":"河北-廊坊-文安县"},
{"address":"河北-廊坊-回族自治县"},
{"address":"河北-廊坊-霸州市"},
{"address":"河北-廊坊-三河市"},
{"address":"河北-衡水-桃城区"},
{"address":"河北-衡水-冀州区"},
{"address":"河北-衡水-枣强县"},
{"address":"河北-衡水-武邑县"},
{"address":"河北-衡水-武强县"},
{"address":"河北-衡水-饶阳县"},
{"address":"河北-衡水-安平县"},
{"address":"河北-衡水-故城县"},
{"address":"河北-衡水-景县"},
{"address":"河北-衡水-阜城县"},
{"address":"河北-衡水-深州市"},
{"address":"山西-太原-小店区"},
{"address":"山西-太原-迎泽区"},
{"address":"山西-太原-杏花岭区"},
{"address":"山西-太原-尖草坪区"},
{"address":"山西-太原-万柏林区"},
{"address":"山西-太原-晋源区"},
{"address":"山西-太原-清徐县"},
{"address":"山西-太原-阳曲县"},
{"address":"山西-太原-娄烦县"},
{"address":"山西-太原-古交市"},
{"address":"山西-大同-新荣区"},
{"address":"山西-大同-平城区"},
{"address":"山西-大同-云冈区"},
{"address":"山西-大同-云州区"},
{"address":"山西-大同-阳高县"},
{"address":"山西-大同-天镇县"},
{"address":"山西-大同-广灵县"},
{"address":"山西-大同-灵丘县"},
{"address":"山西-大同-浑源县"},
{"address":"山西-大同-左云县"},
{"address":"山西-阳泉-城区"},
{"address":"山西-阳泉-矿区"},
{"address":"山西-阳泉-郊区"},
{"address":"山西-阳泉-平定县"},
{"address":"山西-阳泉-盂县"},
{"address":"山西-长治-潞州区"},
{"address":"山西-长治-上党区"},
{"address":"山西-长治-屯留区"},
{"address":"山西-长治-潞城区"},
{"address":"山西-长治-襄垣县"},
{"address":"山西-长治-平顺县"},
{"address":"山西-长治-黎城县"},
{"address":"山西-长治-壶关县"},
{"address":"山西-长治-长子县"},
{"address":"山西-长治-武乡县"},
{"address":"山西-长治-沁县"},
{"address":"山西-长治-沁源县"},
{"address":"山西-晋城-城区"},
{"address":"山西-晋城-沁水县"},
{"address":"山西-晋城-阳城县"},
{"address":"山西-晋城-陵川县"},
{"address":"山西-晋城-泽州县"},
{"address":"山西-晋城-高平市"},
{"address":"山西-朔州-朔城区"},
{"address":"山西-朔州-平鲁区"},
{"address":"山西-朔州-山阴县"},
{"address":"山西-朔州-应县"},
{"address":"山西-朔州-右玉县"},
{"address":"山西-朔州-怀仁市"},
{"address":"山西-晋中-榆次区"},
{"address":"山西-晋中-太谷区"},
{"address":"山西-晋中-榆社县"},
{"address":"山西-晋中-左权县"},
{"address":"山西-晋中-和顺县"},
{"address":"山西-晋中-昔阳县"},
{"address":"山西-晋中-寿阳县"},
{"address":"山西-晋中-祁县"},
{"address":"山西-晋中-平遥县"},
{"address":"山西-晋中-灵石县"},
{"address":"山西-晋中-介休市"},
{"address":"山西-运城-盐湖区"},
{"address":"山西-运城-临猗县"},
{"address":"山西-运城-万荣县"},
{"address":"山西-运城-闻喜县"},
{"address":"山西-运城-稷山县"},
{"address":"山西-运城-新绛县"},
{"address":"山西-运城-绛县"},
{"address":"山西-运城-垣曲县"},
{"address":"山西-运城-夏县"},
{"address":"山西-运城-平陆县"},
{"address":"山西-运城-芮城县"},
{"address":"山西-运城-永济市"},
{"address":"山西-运城-河津市"},
{"address":"山西-忻州-忻府区"},
{"address":"山西-忻州-定襄县"},
{"address":"山西-忻州-五台县"},
{"address":"山西-忻州-代县"},
{"address":"山西-忻州-繁峙县"},
{"address":"山西-忻州-宁武县"},
{"address":"山西-忻州-静乐县"},
{"address":"山西-忻州-神池县"},
{"address":"山西-忻州-五寨县"},
{"address":"山西-忻州-岢岚县"},
{"address":"山西-忻州-河曲县"},
{"address":"山西-忻州-保德县"},
{"address":"山西-忻州-偏关县"},
{"address":"山西-忻州-原平市"},
{"address":"山西-临汾-尧都区"},
{"address":"山西-临汾-曲沃县"},
{"address":"山西-临汾-翼城县"},
{"address":"山西-临汾-襄汾县"},
{"address":"山西-临汾-洪洞县"},
{"address":"山西-临汾-古县"},
{"address":"山西-临汾-安泽县"},
{"address":"山西-临汾-浮山县"},
{"address":"山西-临汾-吉县"},
{"address":"山西-临汾-乡宁县"},
{"address":"山西-临汾-大宁县"},
{"address":"山西-临汾-隰县"},
{"address":"山西-临汾-永和县"},
{"address":"山西-临汾-蒲县"},
{"address":"山西-临汾-汾西县"},
{"address":"山西-临汾-侯马市"},
{"address":"山西-临汾-霍州市"},
{"address":"山西-吕梁-离石区"},
{"address":"山西-吕梁-文水县"},
{"address":"山西-吕梁-交城县"},
{"address":"山西-吕梁-兴县"},
{"address":"山西-吕梁-临县"},
{"address":"山西-吕梁-柳林县"},
{"address":"山西-吕梁-石楼县"},
{"address":"山西-吕梁-岚县"},
{"address":"山西-吕梁-方山县"},
{"address":"山西-吕梁-中阳县"},
{"address":"山西-吕梁-交口县"},
{"address":"山西-吕梁-孝义市"},
{"address":"山西-吕梁-汾阳市"},
{"address":"内蒙古-呼和浩特-新城区"},
{"address":"内蒙古-呼和浩特-回民区"},
{"address":"内蒙古-呼和浩特-玉泉区"},
{"address":"内蒙古-呼和浩特-赛罕区"},
{"address":"内蒙古-呼和浩特-土默特左旗"},
{"address":"内蒙古-呼和浩特-托克托县"},
{"address":"内蒙古-呼和浩特-和林格尔县"},
{"address":"内蒙古-呼和浩特-清水河县"},
{"address":"内蒙古-呼和浩特-武川县"},
{"address":"内蒙古-包头-东河区"},
{"address":"内蒙古-包头-昆都仑区"},
{"address":"内蒙古-包头-青山区"},
{"address":"内蒙古-包头-石拐区"},
{"address":"内蒙古-包头-白云鄂博矿区"},
{"address":"内蒙古-包头-九原区"},
{"address":"内蒙古-包头-土默特右旗"},
{"address":"内蒙古-包头-固阳县"},
{"address":"内蒙古-包头-达尔罕茂明安联合旗"},
{"address":"内蒙古-乌海-海勃湾区"},
{"address":"内蒙古-乌海-海南区"},
{"address":"内蒙古-乌海-乌达区"},
{"address":"内蒙古-赤峰-红山区"},
{"address":"内蒙古-赤峰-元宝山区"},
{"address":"内蒙古-赤峰-松山区"},
{"address":"内蒙古-赤峰-阿鲁科尔沁旗"},
{"address":"内蒙古-赤峰-巴林左旗"},
{"address":"内蒙古-赤峰-巴林右旗"},
{"address":"内蒙古-赤峰-林西县"},
{"address":"内蒙古-赤峰-克什克腾旗"},
{"address":"内蒙古-赤峰-翁牛特旗"},
{"address":"内蒙古-赤峰-喀喇沁旗"},
{"address":"内蒙古-赤峰-宁城县"},
{"address":"内蒙古-赤峰-敖汉旗"},
{"address":"内蒙古-通辽-科尔沁区"},
{"address":"内蒙古-通辽-科尔沁左翼中旗"},
{"address":"内蒙古-通辽-科尔沁左翼后旗"},
{"address":"内蒙古-通辽-开鲁县"},
{"address":"内蒙古-通辽-库伦旗"},
{"address":"内蒙古-通辽-奈曼旗"},
{"address":"内蒙古-通辽-扎鲁特旗"},
{"address":"内蒙古-通辽-霍林郭勒市"},
{"address":"内蒙古-鄂尔多斯-东胜区"},
{"address":"内蒙古-鄂尔多斯-康巴什区"},
{"address":"内蒙古-鄂尔多斯-达拉特旗"},
{"address":"内蒙古-鄂尔多斯-准格尔旗"},
{"address":"内蒙古-鄂尔多斯-鄂托克前旗"},
{"address":"内蒙古-鄂尔多斯-鄂托克旗"},
{"address":"内蒙古-鄂尔多斯-杭锦旗"},
{"address":"内蒙古-鄂尔多斯-乌审旗"},
{"address":"内蒙古-鄂尔多斯-伊金霍洛旗"},
{"address":"内蒙古-呼伦贝尔-海拉尔区"},
{"address":"内蒙古-呼伦贝尔-扎赉诺尔区"},
{"address":"内蒙古-呼伦贝尔-阿荣旗"},
{"address":"内蒙古-呼伦贝尔-莫力达瓦"},
{"address":"内蒙古-呼伦贝尔-鄂伦春自治旗"},
{"address":"内蒙古-呼伦贝尔-鄂温克族自治旗"},
{"address":"内蒙古-呼伦贝尔-陈巴尔虎旗"},
{"address":"内蒙古-呼伦贝尔-新巴尔虎左旗"},
{"address":"内蒙古-呼伦贝尔-新巴尔虎右旗"},
{"address":"内蒙古-呼伦贝尔-满洲里市"},
{"address":"内蒙古-呼伦贝尔-牙克石市"},
{"address":"内蒙古-呼伦贝尔-扎兰屯市"},
{"address":"内蒙古-呼伦贝尔-额尔古纳市"},
{"address":"内蒙古-呼伦贝尔-根河市"},
{"address":"内蒙古-巴彦淖尔-临河区"},
{"address":"内蒙古-巴彦淖尔-五原县"},
{"address":"内蒙古-巴彦淖尔-磴口县"},
{"address":"内蒙古-巴彦淖尔-乌拉特前旗"},
{"address":"内蒙古-巴彦淖尔-乌拉特中旗"},
{"address":"内蒙古-巴彦淖尔-乌拉特后旗"},
{"address":"内蒙古-巴彦淖尔-杭锦后旗"},
{"address":"内蒙古-乌兰察布-集宁区"},
{"address":"内蒙古-乌兰察布-卓资县"},
{"address":"内蒙古-乌兰察布-化德县"},
{"address":"内蒙古-乌兰察布-商都县"},
{"address":"内蒙古-乌兰察布-兴和县"},
{"address":"内蒙古-乌兰察布-凉城县"},
{"address":"内蒙古-乌兰察布-察哈尔右翼前旗"},
{"address":"内蒙古-乌兰察布-察哈尔右翼中旗"},
{"address":"内蒙古-乌兰察布-察哈尔右翼后旗"},
{"address":"内蒙古-乌兰察布-四子王旗"},
{"address":"内蒙古-乌兰察布-丰镇市"},
{"address":"内蒙古-兴安-乌兰浩特市"},
{"address":"内蒙古-兴安-阿尔山市"},
{"address":"内蒙古-兴安-科尔沁右翼前旗"},
{"address":"内蒙古-兴安-科尔沁右翼中旗"},
{"address":"内蒙古-兴安-扎赉特旗"},
{"address":"内蒙古-兴安-突泉县"},
{"address":"内蒙古-锡林郭勒-二连浩特市"},
{"address":"内蒙古-锡林郭勒-锡林浩特市"},
{"address":"内蒙古-锡林郭勒-阿巴嘎旗"},
{"address":"内蒙古-锡林郭勒-苏尼特左旗"},
{"address":"内蒙古-锡林郭勒-苏尼特右旗"},
{"address":"内蒙古-锡林郭勒-东乌珠穆沁旗"},
{"address":"内蒙古-锡林郭勒-西乌珠穆沁旗"},
{"address":"内蒙古-锡林郭勒-太仆寺旗"},
{"address":"内蒙古-锡林郭勒-镶黄旗"},
{"address":"内蒙古-锡林郭勒-正镶白旗"},
{"address":"内蒙古-锡林郭勒-正蓝旗"},
{"address":"内蒙古-锡林郭勒-多伦县"},
{"address":"内蒙古-阿拉善-阿拉善盟"},
{"address":"内蒙古-阿拉善-阿拉善左旗"},
{"address":"内蒙古-阿拉善-阿拉善右旗"},
{"address":"内蒙古-阿拉善-额济纳旗"},
{"address":"辽宁-沈阳-和平区"},
{"address":"辽宁-沈阳-沈河区"},
{"address":"辽宁-沈阳-大东区"},
{"address":"辽宁-沈阳-皇姑区"},
{"address":"辽宁-沈阳-铁西区"},
{"address":"辽宁-沈阳-苏家屯区"},
{"address":"辽宁-沈阳-浑南区"},
{"address":"辽宁-沈阳-沈北新区"},
{"address":"辽宁-沈阳-于洪区"},
{"address":"辽宁-沈阳-辽中区"},
{"address":"辽宁-沈阳-康平县"},
{"address":"辽宁-沈阳-法库县"},
{"address":"辽宁-沈阳-新民市"},
{"address":"辽宁-大连-中山区"},
{"address":"辽宁-大连-西岗区"},
{"address":"辽宁-大连-沙河口区"},
{"address":"辽宁-大连-甘井子区"},
{"address":"辽宁-大连-旅顺口区"},
{"address":"辽宁-大连-金州区"},
{"address":"辽宁-大连-普兰店区"},
{"address":"辽宁-大连-长海县"},
{"address":"辽宁-大连-瓦房店市"},
{"address":"辽宁-大连-庄河市"},
{"address":"辽宁-鞍山-铁东区"},
{"address":"辽宁-鞍山-铁西区"},
{"address":"辽宁-鞍山-立山区"},
{"address":"辽宁-鞍山-千山区"},
{"address":"辽宁-鞍山-台安县"},
{"address":"辽宁-鞍山-满族自治县"},
{"address":"辽宁-鞍山-海城市"},
{"address":"辽宁-抚顺-新抚区"},
{"address":"辽宁-抚顺-东洲区"},
{"address":"辽宁-抚顺-望花区"},
{"address":"辽宁-抚顺-顺城区"},
{"address":"辽宁-抚顺-抚顺县"},
{"address":"辽宁-抚顺-新宾"},
{"address":"辽宁-抚顺-清原"},
{"address":"辽宁-本溪-平山区"},
{"address":"辽宁-本溪-溪湖区"},
{"address":"辽宁-本溪-明山区"},
{"address":"辽宁-本溪-南芬区"},
{"address":"辽宁-本溪-本溪满族自治县"},
{"address":"辽宁-本溪-桓仁满族自治县"},
{"address":"辽宁-丹东-元宝区"},
{"address":"辽宁-丹东-振兴区"},
{"address":"辽宁-丹东-振安区"},
{"address":"辽宁-丹东-宽甸满族自治县"},
{"address":"辽宁-丹东-东港市"},
{"address":"辽宁-丹东-凤城市"},
{"address":"辽宁-锦州-古塔区"},
{"address":"辽宁-锦州-凌河区"},
{"address":"辽宁-锦州-太和区"},
{"address":"辽宁-锦州-黑山县"},
{"address":"辽宁-锦州-义县"},
{"address":"辽宁-锦州-凌海市"},
{"address":"辽宁-锦州-北镇市"},
{"address":"辽宁-营口-站前区"},
{"address":"辽宁-营口-西市区"},
{"address":"辽宁-营口-鲅鱼圈区"},
{"address":"辽宁-营口-老边区"},
{"address":"辽宁-营口-盖州市"},
{"address":"辽宁-营口-大石桥市"},
{"address":"辽宁-阜新-海州区"},
{"address":"辽宁-阜新-新邱区"},
{"address":"辽宁-阜新-太平区"},
{"address":"辽宁-阜新-清河门区"},
{"address":"辽宁-阜新-细河区"},
{"address":"辽宁-阜新-阜新蒙古族自治县"},
{"address":"辽宁-阜新-彰武县"},
{"address":"辽宁-辽阳-白塔区"},
{"address":"辽宁-辽阳-文圣区"},
{"address":"辽宁-辽阳-宏伟区"},
{"address":"辽宁-辽阳-弓长岭区"},
{"address":"辽宁-辽阳-太子河区"},
{"address":"辽宁-辽阳-辽阳县"},
{"address":"辽宁-辽阳-灯塔市"},
{"address":"辽宁-盘锦-双台子区"},
{"address":"辽宁-盘锦-兴隆台区"},
{"address":"辽宁-盘锦-大洼区"},
{"address":"辽宁-盘锦-盘山县"},
{"address":"辽宁-铁岭-银州区"},
{"address":"辽宁-铁岭-清河区"},
{"address":"辽宁-铁岭-铁岭县"},
{"address":"辽宁-铁岭-西丰县"},
{"address":"辽宁-铁岭-昌图县"},
{"address":"辽宁-铁岭-调兵山市"},
{"address":"辽宁-铁岭-开原市"},
{"address":"辽宁-朝阳-双塔区"},
{"address":"辽宁-朝阳-龙城区"},
{"address":"辽宁-朝阳-朝阳县"},
{"address":"辽宁-朝阳-建平县"},
{"address":"辽宁-朝阳-喀喇沁左翼"},
{"address":"辽宁-朝阳-北票市"},
{"address":"辽宁-朝阳-凌源市"},
{"address":"辽宁-葫芦岛-连山区"},
{"address":"辽宁-葫芦岛-龙港区"},
{"address":"辽宁-葫芦岛-南票区"},
{"address":"辽宁-葫芦岛-绥中县"},
{"address":"辽宁-葫芦岛-建昌县"},
{"address":"辽宁-葫芦岛-兴城市"},
{"address":"吉林-长春-南关区"},
{"address":"吉林-长春-宽城区"},
{"address":"吉林-长春-朝阳区"},
{"address":"吉林-长春-二道区"},
{"address":"吉林-长春-绿园区"},
{"address":"吉林-长春-双阳区"},
{"address":"吉林-长春-九台区"},
{"address":"吉林-长春-农安县"},
{"address":"吉林-长春-榆树市"},
{"address":"吉林-长春-德惠市"},
{"address":"吉林-长春-公主岭市"},
{"address":"吉林-吉林-昌邑区"},
{"address":"吉林-吉林-龙潭区"},
{"address":"吉林-吉林-船营区"},
{"address":"吉林-吉林-丰满区"},
{"address":"吉林-吉林-永吉县"},
{"address":"吉林-吉林-蛟河市"},
{"address":"吉林-吉林-桦甸市"},
{"address":"吉林-吉林-舒兰市"},
{"address":"吉林-吉林-磐石市"},
{"address":"吉林-四平-铁西区"},
{"address":"吉林-四平-铁东区"},
{"address":"吉林-四平-梨树县"},
{"address":"吉林-四平-伊通满族自治县"},
{"address":"吉林-四平-双辽市"},
{"address":"吉林-辽源-龙山区"},
{"address":"吉林-辽源-西安区"},
{"address":"吉林-辽源-东丰县"},
{"address":"吉林-辽源-东辽县"},
{"address":"吉林-通化-东昌区"},
{"address":"吉林-通化-二道江区"},
{"address":"吉林-通化-通化县"},
{"address":"吉林-通化-辉南县"},
{"address":"吉林-通化-柳河县"},
{"address":"吉林-通化-梅河口市"},
{"address":"吉林-通化-集安市"},
{"address":"吉林-白山-浑江区"},
{"address":"吉林-白山-江源区"},
{"address":"吉林-白山-抚松县"},
{"address":"吉林-白山-靖宇县"},
{"address":"吉林-白山-长白朝鲜族自治县"},
{"address":"吉林-白山-临江市"},
{"address":"吉林-松原-宁江区"},
{"address":"吉林-松原-前郭尔罗斯"},
{"address":"吉林-松原-长岭县"},
{"address":"吉林-松原-乾安县"},
{"address":"吉林-松原-扶余市"},
{"address":"吉林-白城-白城市"},
{"address":"吉林-白城-洮北区"},
{"address":"吉林-白城-镇赉县"},
{"address":"吉林-白城-通榆县"},
{"address":"吉林-白城-洮南市"},
{"address":"吉林-白城-大安市"},
{"address":"吉林-延边-延吉市"},
{"address":"吉林-延边-图们市"},
{"address":"吉林-延边-敦化市"},
{"address":"吉林-延边-珲春市"},
{"address":"吉林-延边-龙井市"},
{"address":"吉林-延边-和龙市"},
{"address":"吉林-延边-汪清县"},
{"address":"吉林-延边-安图县"},
{"address":"黑龙江-哈尔滨-道里区"},
{"address":"黑龙江-哈尔滨-南岗区"},
{"address":"黑龙江-哈尔滨-道外区"},
{"address":"黑龙江-哈尔滨-平房区"},
{"address":"黑龙江-哈尔滨-松北区"},
{"address":"黑龙江-哈尔滨-香坊区"},
{"address":"黑龙江-哈尔滨-呼兰区"},
{"address":"黑龙江-哈尔滨-阿城区"},
{"address":"黑龙江-哈尔滨-双城区"},
{"address":"黑龙江-哈尔滨-依兰县"},
{"address":"黑龙江-哈尔滨-方正县"},
{"address":"黑龙江-哈尔滨-宾县"},
{"address":"黑龙江-哈尔滨-巴彦县"},
{"address":"黑龙江-哈尔滨-木兰县"},
{"address":"黑龙江-哈尔滨-通河县"},
{"address":"黑龙江-哈尔滨-延寿县"},
{"address":"黑龙江-哈尔滨-尚志市"},
{"address":"黑龙江-哈尔滨-五常市"},
{"address":"黑龙江-齐齐哈尔-龙沙区"},
{"address":"黑龙江-齐齐哈尔-建华区"},
{"address":"黑龙江-齐齐哈尔-铁锋区"},
{"address":"黑龙江-齐齐哈尔-昂昂溪区"},
{"address":"黑龙江-齐齐哈尔-富拉尔基区"},
{"address":"黑龙江-齐齐哈尔-碾子山区"},
{"address":"黑龙江-齐齐哈尔-梅里斯达斡尔族区"},
{"address":"黑龙江-齐齐哈尔-龙江县"},
{"address":"黑龙江-齐齐哈尔-依安县"},
{"address":"黑龙江-齐齐哈尔-泰来县"},
{"address":"黑龙江-齐齐哈尔-甘南县"},
{"address":"黑龙江-齐齐哈尔-富裕县"},
{"address":"黑龙江-齐齐哈尔-克山县"},
{"address":"黑龙江-齐齐哈尔-克东县"},
{"address":"黑龙江-齐齐哈尔-拜泉县"},
{"address":"黑龙江-齐齐哈尔-讷河市"},
{"address":"黑龙江-鸡西-鸡冠区"},
{"address":"黑龙江-鸡西-恒山区"},
{"address":"黑龙江-鸡西-滴道区"},
{"address":"黑龙江-鸡西-梨树区"},
{"address":"黑龙江-鸡西-城子河区"},
{"address":"黑龙江-鸡西-麻山区"},
{"address":"黑龙江-鸡西-鸡东县"},
{"address":"黑龙江-鸡西-虎林市"},
{"address":"黑龙江-鸡西-密山市"},
{"address":"黑龙江-鹤岗-向阳区"},
{"address":"黑龙江-鹤岗-工农区"},
{"address":"黑龙江-鹤岗-南山区"},
{"address":"黑龙江-鹤岗-兴安区"},
{"address":"黑龙江-鹤岗-东山区"},
{"address":"黑龙江-鹤岗-兴山区"},
{"address":"黑龙江-鹤岗-萝北县"},
{"address":"黑龙江-鹤岗-绥滨县"},
{"address":"黑龙江-双鸭山-尖山区"},
{"address":"黑龙江-双鸭山-岭东区"},
{"address":"黑龙江-双鸭山-四方台区"},
{"address":"黑龙江-双鸭山-宝山区"},
{"address":"黑龙江-双鸭山-集贤县"},
{"address":"黑龙江-双鸭山-友谊县"},
{"address":"黑龙江-双鸭山-宝清县"},
{"address":"黑龙江-双鸭山-饶河县"},
{"address":"黑龙江-大庆-萨尔图区"},
{"address":"黑龙江-大庆-龙凤区"},
{"address":"黑龙江-大庆-让胡路区"},
{"address":"黑龙江-大庆-红岗区"},
{"address":"黑龙江-大庆-大同区"},
{"address":"黑龙江-大庆-肇州县"},
{"address":"黑龙江-大庆-肇源县"},
{"address":"黑龙江-大庆-林甸县"},
{"address":"黑龙江-大庆-古族自治县"},
{"address":"黑龙江-伊春-伊美区"},
{"address":"黑龙江-伊春-乌翠区"},
{"address":"黑龙江-伊春-友好区"},
{"address":"黑龙江-伊春-嘉荫县"},
{"address":"黑龙江-伊春-汤旺县"},
{"address":"黑龙江-伊春-丰林县"},
{"address":"黑龙江-伊春-大箐山县"},
{"address":"黑龙江-伊春-南岔县"},
{"address":"黑龙江-伊春-金林区"},
{"address":"黑龙江-伊春-铁力市"},
{"address":"黑龙江-佳木斯-向阳区"},
{"address":"黑龙江-佳木斯-前进区"},
{"address":"黑龙江-佳木斯-东风区"},
{"address":"黑龙江-佳木斯-郊区"},
{"address":"黑龙江-佳木斯-桦南县"},
{"address":"黑龙江-佳木斯-桦川县"},
{"address":"黑龙江-佳木斯-汤原县"},
{"address":"黑龙江-佳木斯-同江市"},
{"address":"黑龙江-佳木斯-富锦市"},
{"address":"黑龙江-佳木斯-抚远市"},
{"address":"黑龙江-七台河-新兴区"},
{"address":"黑龙江-七台河-桃山区"},
{"address":"黑龙江-七台河-茄子河区"},
{"address":"黑龙江-七台河-勃利县"},
{"address":"黑龙江-牡丹江-东安区"},
{"address":"黑龙江-牡丹江-阳明区"},
{"address":"黑龙江-牡丹江-爱民区"},
{"address":"黑龙江-牡丹江-西安区"},
{"address":"黑龙江-牡丹江-林口县"},
{"address":"黑龙江-牡丹江-绥芬河市"},
{"address":"黑龙江-牡丹江-海林市"},
{"address":"黑龙江-牡丹江-宁安市"},
{"address":"黑龙江-牡丹江-穆棱市"},
{"address":"黑龙江-牡丹江-东宁市"},
{"address":"黑龙江-黑河-爱辉区"},
{"address":"黑龙江-黑河-逊克县"},
{"address":"黑龙江-黑河-孙吴县"},
{"address":"黑龙江-黑河-北安市"},
{"address":"黑龙江-黑河-五大连池市"},
{"address":"黑龙江-黑河-嫩江市"},
{"address":"黑龙江-绥化-北林区"},
{"address":"黑龙江-绥化-望奎县"},
{"address":"黑龙江-绥化-兰西县"},
{"address":"黑龙江-绥化-青冈县"},
{"address":"黑龙江-绥化-庆安县"},
{"address":"黑龙江-绥化-明水县"},
{"address":"黑龙江-绥化-绥棱县"},
{"address":"黑龙江-绥化-安达市"},
{"address":"黑龙江-绥化-肇东市"},
{"address":"黑龙江-绥化-海伦市"},
{"address":"黑龙江-大兴安岭"},
{"address":"黑龙江-大兴安岭-漠河市"},
{"address":"黑龙江-大兴安岭-加格达奇区"},
{"address":"黑龙江-大兴安岭-呼玛县"},
{"address":"黑龙江-大兴安岭-塔河县"},
{"address":"上海-上海-上海市"},
{"address":"上海-上海-黄浦区"},
{"address":"上海-上海-徐汇区"},
{"address":"上海-上海-长宁区"},
{"address":"上海-上海-静安区"},
{"address":"上海-上海-普陀区"},
{"address":"上海-上海-虹口区"},
{"address":"上海-上海-杨浦区"},
{"address":"上海-上海-闵行区"},
{"address":"上海-上海-宝山区"},
{"address":"上海-上海-嘉定区"},
{"address":"上海-上海-浦东新区"},
{"address":"上海-上海-金山区"},
{"address":"上海-上海-松江区"},
{"address":"上海-上海-青浦区"},
{"address":"上海-上海-奉贤区"},
{"address":"上海-上海-崇明区"},
{"address":"江苏-南京-玄武区"},
{"address":"江苏-南京-秦淮区"},
{"address":"江苏-南京-建邺区"},
{"address":"江苏-南京-鼓楼区"},
{"address":"江苏-南京-浦口区"},
{"address":"江苏-南京-栖霞区"},
{"address":"江苏-南京-雨花台区"},
{"address":"江苏-南京-江宁区"},
{"address":"江苏-南京-六合区"},
{"address":"江苏-南京-溧水区"},
{"address":"江苏-南京-高淳区"},
{"address":"江苏-无锡-锡山区"},
{"address":"江苏-无锡-惠山区"},
{"address":"江苏-无锡-滨湖区"},
{"address":"江苏-无锡-梁溪区"},
{"address":"江苏-无锡-新吴区"},
{"address":"江苏-无锡-江阴市"},
{"address":"江苏-无锡-宜兴市"},
{"address":"江苏-徐州-鼓楼区"},
{"address":"江苏-徐州-云龙区"},
{"address":"江苏-徐州-贾汪区"},
{"address":"江苏-徐州-泉山区"},
{"address":"江苏-徐州-铜山区"},
{"address":"江苏-徐州-丰县"},
{"address":"江苏-徐州-沛县"},
{"address":"江苏-徐州-睢宁县"},
{"address":"江苏-徐州-新沂市"},
{"address":"江苏-徐州-邳州市"},
{"address":"江苏-常州-天宁区"},
{"address":"江苏-常州-钟楼区"},
{"address":"江苏-常州-新北区"},
{"address":"江苏-常州-武进区"},
{"address":"江苏-常州-金坛区"},
{"address":"江苏-常州-溧阳市"},
{"address":"江苏-苏州-虎丘区"},
{"address":"江苏-苏州-吴中区"},
{"address":"江苏-苏州-相城区"},
{"address":"江苏-苏州-姑苏区"},
{"address":"江苏-苏州-吴江区"},
{"address":"江苏-苏州-常熟市"},
{"address":"江苏-苏州-张家港市"},
{"address":"江苏-苏州-昆山市"},
{"address":"江苏-苏州-太仓市"},
{"address":"江苏-南通-通州区"},
{"address":"江苏-南通-崇川区"},
{"address":"江苏-南通-海门区"},
{"address":"江苏-南通-如东县"},
{"address":"江苏-南通-启东市"},
{"address":"江苏-南通-如皋市"},
{"address":"江苏-南通-海安市"},
{"address":"江苏-连云港-连云区"},
{"address":"江苏-连云港-海州区"},
{"address":"江苏-连云港-赣榆区"},
{"address":"江苏-连云港-东海县"},
{"address":"江苏-连云港-灌云县"},
{"address":"江苏-连云港-灌南县"},
{"address":"江苏-淮安-淮安区"},
{"address":"江苏-淮安-淮阴区"},
{"address":"江苏-淮安-清江浦区"},
{"address":"江苏-淮安-洪泽区"},
{"address":"江苏-淮安-涟水县"},
{"address":"江苏-淮安-盱眙县"},
{"address":"江苏-淮安-金湖县"},
{"address":"江苏-盐城-亭湖区"},
{"address":"江苏-盐城-盐都区"},
{"address":"江苏-盐城-大丰区"},
{"address":"江苏-盐城-响水县"},
{"address":"江苏-盐城-滨海县"},
{"address":"江苏-盐城-阜宁县"},
{"address":"江苏-盐城-射阳县"},
{"address":"江苏-盐城-建湖县"},
{"address":"江苏-盐城-东台市"},
{"address":"江苏-扬州-广陵区"},
{"address":"江苏-扬州-邗江区"},
{"address":"江苏-扬州-江都区"},
{"address":"江苏-扬州-宝应县"},
{"address":"江苏-扬州-仪征市"},
{"address":"江苏-扬州-高邮市"},
{"address":"江苏-镇江-京口区"},
{"address":"江苏-镇江-润州区"},
{"address":"江苏-镇江-丹徒区"},
{"address":"江苏-镇江-丹阳市"},
{"address":"江苏-镇江-扬中市"},
{"address":"江苏-镇江-句容市"},
{"address":"江苏-泰州-海陵区"},
{"address":"江苏-泰州-高港区"},
{"address":"江苏-泰州-姜堰区"},
{"address":"江苏-泰州-兴化市"},
{"address":"江苏-泰州-靖江市"},
{"address":"江苏-泰州-泰兴市"},
{"address":"江苏-宿迁-宿城区"},
{"address":"江苏-宿迁-宿豫区"},
{"address":"江苏-宿迁-沭阳县"},
{"address":"江苏-宿迁-泗阳县"},
{"address":"江苏-宿迁-泗洪县"},
{"address":"浙江-杭州-上城区"},
{"address":"浙江-杭州-拱墅区"},
{"address":"浙江-杭州-西湖区"},
{"address":"浙江-杭州-滨江区"},
{"address":"浙江-杭州-萧山区"},
{"address":"浙江-杭州-余杭区"},
{"address":"浙江-杭州-富阳区"},
{"address":"浙江-杭州-临安区"},
{"address":"浙江-杭州-临平区"},
{"address":"浙江-杭州-钱塘区"},
{"address":"浙江-杭州-桐庐县"},
{"address":"浙江-杭州-淳安县"},
{"address":"浙江-杭州-建德市"},
{"address":"浙江-宁波-海曙区"},
{"address":"浙江-宁波-江北区"},
{"address":"浙江-宁波-北仑区"},
{"address":"浙江-宁波-镇海区"},
{"address":"浙江-宁波-鄞州区"},
{"address":"浙江-宁波-奉化区"},
{"address":"浙江-宁波-象山县"},
{"address":"浙江-宁波-宁海县"},
{"address":"浙江-宁波-余姚市"},
{"address":"浙江-宁波-慈溪市"},
{"address":"浙江-温州-鹿城区"},
{"address":"浙江-温州-龙湾区"},
{"address":"浙江-温州-瓯海区"},
{"address":"浙江-温州-洞头区"},
{"address":"浙江-温州-永嘉县"},
{"address":"浙江-温州-平阳县"},
{"address":"浙江-温州-苍南县"},
{"address":"浙江-温州-文成县"},
{"address":"浙江-温州-泰顺县"},
{"address":"浙江-温州-瑞安市"},
{"address":"浙江-温州-乐清市"},
{"address":"浙江-温州-龙港市"},
{"address":"浙江-嘉兴-南湖区"},
{"address":"浙江-嘉兴-秀洲区"},
{"address":"浙江-嘉兴-嘉善县"},
{"address":"浙江-嘉兴-海盐县"},
{"address":"浙江-嘉兴-海宁市"},
{"address":"浙江-嘉兴-平湖市"},
{"address":"浙江-嘉兴-桐乡市"},
{"address":"浙江-湖州-吴兴区"},
{"address":"浙江-湖州-南浔区"},
{"address":"浙江-湖州-德清县"},
{"address":"浙江-湖州-长兴县"},
{"address":"浙江-湖州-安吉县"},
{"address":"浙江-绍兴-越城区"},
{"address":"浙江-绍兴-柯桥区"},
{"address":"浙江-绍兴-上虞区"},
{"address":"浙江-绍兴-新昌县"},
{"address":"浙江-绍兴-诸暨市"},
{"address":"浙江-绍兴-嵊州市"},
{"address":"浙江-金华-婺城区"},
{"address":"浙江-金华-金东区"},
{"address":"浙江-金华-武义县"},
{"address":"浙江-金华-浦江县"},
{"address":"浙江-金华-磐安县"},
{"address":"浙江-金华-兰溪市"},
{"address":"浙江-金华-义乌市"},
{"address":"浙江-金华-东阳市"},
{"address":"浙江-金华-永康市"},
{"address":"浙江-衢州-柯城区"},
{"address":"浙江-衢州-衢江区"},
{"address":"浙江-衢州-常山县"},
{"address":"浙江-衢州-开化县"},
{"address":"浙江-衢州-龙游县"},
{"address":"浙江-衢州-江山市"},
{"address":"浙江-舟山-定海区"},
{"address":"浙江-舟山-普陀区"},
{"address":"浙江-舟山-岱山县"},
{"address":"浙江-舟山-嵊泗县"},
{"address":"浙江-台州-椒江区"},
{"address":"浙江-台州-黄岩区"},
{"address":"浙江-台州-路桥区"},
{"address":"浙江-台州-三门县"},
{"address":"浙江-台州-天台县"},
{"address":"浙江-台州-仙居县"},
{"address":"浙江-台州-温岭市"},
{"address":"浙江-台州-临海市"},
{"address":"浙江-台州-玉环市"},
{"address":"浙江-丽水-莲都区"},
{"address":"浙江-丽水-青田县"},
{"address":"浙江-丽水-缙云县"},
{"address":"浙江-丽水-遂昌县"},
{"address":"浙江-丽水-松阳县"},
{"address":"浙江-丽水-云和县"},
{"address":"浙江-丽水-庆元县"},
{"address":"浙江-丽水-畲族自治县"},
{"address":"浙江-丽水-龙泉市"},
{"address":"安徽-合肥-瑶海区"},
{"address":"安徽-合肥-庐阳区"},
{"address":"安徽-合肥-蜀山区"},
{"address":"安徽-合肥-包河区"},
{"address":"安徽-合肥-长丰县"},
{"address":"安徽-合肥-肥东县"},
{"address":"安徽-合肥-肥西县"},
{"address":"安徽-合肥-庐江县"},
{"address":"安徽-合肥-巢湖市"},
{"address":"安徽-合肥-滨湖新区"},
{"address":"安徽-芜湖-镜湖区"},
{"address":"安徽-芜湖-鸠江区"},
{"address":"安徽-芜湖-弋江区"},
{"address":"安徽-芜湖-湾沚区"},
{"address":"安徽-芜湖-繁昌区"},
{"address":"安徽-芜湖-南陵县"},
{"address":"安徽-芜湖-无为市"},
{"address":"安徽-蚌埠-龙子湖区"},
{"address":"安徽-蚌埠-蚌山区"},
{"address":"安徽-蚌埠-禹会区"},
{"address":"安徽-蚌埠-淮上区"},
{"address":"安徽-蚌埠-怀远县"},
{"address":"安徽-蚌埠-五河县"},
{"address":"安徽-蚌埠-固镇县"},
{"address":"安徽-淮南-大通区"},
{"address":"安徽-淮南-田家庵区"},
{"address":"安徽-淮南-谢家集区"},
{"address":"安徽-淮南-八公山区"},
{"address":"安徽-淮南-潘集区"},
{"address":"安徽-淮南-凤台县"},
{"address":"安徽-淮南-寿县"},
{"address":"安徽-马鞍山-花山区"},
{"address":"安徽-马鞍山-雨山区"},
{"address":"安徽-马鞍山-博望区"},
{"address":"安徽-马鞍山-当涂县"},
{"address":"安徽-马鞍山-含山县"},
{"address":"安徽-马鞍山-和县"},
{"address":"安徽-淮北-杜集区"},
{"address":"安徽-淮北-相山区"},
{"address":"安徽-淮北-烈山区"},
{"address":"安徽-淮北-濉溪县"},
{"address":"安徽-铜陵-铜官区"},
{"address":"安徽-铜陵-义安区"},
{"address":"安徽-铜陵-郊区"},
{"address":"安徽-铜陵-枞阳县"},
{"address":"安徽-安庆-迎江区"},
{"address":"安徽-安庆-大观区"},
{"address":"安徽-安庆-宜秀区"},
{"address":"安徽-安庆-怀宁县"},
{"address":"安徽-安庆-太湖县"},
{"address":"安徽-安庆-宿松县"},
{"address":"安徽-安庆-望江县"},
{"address":"安徽-安庆-岳西县"},
{"address":"安徽-安庆-桐城市"},
{"address":"安徽-安庆-潜山市"},
{"address":"安徽-黄山-屯溪区"},
{"address":"安徽-黄山-黄山区"},
{"address":"安徽-黄山-徽州区"},
{"address":"安徽-黄山-歙县"},
{"address":"安徽-黄山-休宁县"},
{"address":"安徽-黄山-黟县"},
{"address":"安徽-黄山-祁门县"},
{"address":"安徽-滁州-琅琊区"},
{"address":"安徽-滁州-南谯区"},
{"address":"安徽-滁州-来安县"},
{"address":"安徽-滁州-全椒县"},
{"address":"安徽-滁州-定远县"},
{"address":"安徽-滁州-凤阳县"},
{"address":"安徽-滁州-天长市"},
{"address":"安徽-滁州-明光市"},
{"address":"安徽-阜阳-颍州区"},
{"address":"安徽-阜阳-颍东区"},
{"address":"安徽-阜阳-颍泉区"},
{"address":"安徽-阜阳-临泉县"},
{"address":"安徽-阜阳-太和县"},
{"address":"安徽-阜阳-阜南县"},
{"address":"安徽-阜阳-颍上县"},
{"address":"安徽-阜阳-界首市"},
{"address":"安徽-宿州-埇桥区"},
{"address":"安徽-宿州-砀山县"},
{"address":"安徽-宿州-萧县"},
{"address":"安徽-宿州-灵璧县"},
{"address":"安徽-宿州-泗县"},
{"address":"安徽-六安-金安区"},
{"address":"安徽-六安-裕安区"},
{"address":"安徽-六安-叶集区"},
{"address":"安徽-六安-霍邱县"},
{"address":"安徽-六安-舒城县"},
{"address":"安徽-六安-金寨县"},
{"address":"安徽-六安-霍山县"},
{"address":"安徽-亳州-谯城区"},
{"address":"安徽-亳州-涡阳县"},
{"address":"安徽-亳州-蒙城县"},
{"address":"安徽-亳州-利辛县"},
{"address":"安徽-池州-贵池区"},
{"address":"安徽-池州-东至县"},
{"address":"安徽-池州-石台县"},
{"address":"安徽-池州-青阳县"},
{"address":"安徽-宣城-宣州区"},
{"address":"安徽-宣城-郎溪县"},
{"address":"安徽-宣城-泾县"},
{"address":"安徽-宣城-绩溪县"},
{"address":"安徽-宣城-旌德县"},
{"address":"安徽-宣城-宁国市"},
{"address":"安徽-宣城-广德市"},
{"address":"福建-福州-鼓楼区"},
{"address":"福建-福州-台江区"},
{"address":"福建-福州-仓山区"},
{"address":"福建-福州-马尾区"},
{"address":"福建-福州-晋安区"},
{"address":"福建-福州-长乐区"},
{"address":"福建-福州-闽侯县"},
{"address":"福建-福州-连江县"},
{"address":"福建-福州-罗源县"},
{"address":"福建-福州-闽清县"},
{"address":"福建-福州-永泰县"},
{"address":"福建-福州-平潭县"},
{"address":"福建-福州-福清市"},
{"address":"福建-厦门-思明区"},
{"address":"福建-厦门-海沧区"},
{"address":"福建-厦门-湖里区"},
{"address":"福建-厦门-集美区"},
{"address":"福建-厦门-同安区"},
{"address":"福建-厦门-翔安区"},
{"address":"福建-莆田-城厢区"},
{"address":"福建-莆田-涵江区"},
{"address":"福建-莆田-荔城区"},
{"address":"福建-莆田-秀屿区"},
{"address":"福建-莆田-仙游县"},
{"address":"福建-三明-三元区"},
{"address":"福建-三明-沙县区"},
{"address":"福建-三明-明溪县"},
{"address":"福建-三明-清流县"},
{"address":"福建-三明-宁化县"},
{"address":"福建-三明-大田县"},
{"address":"福建-三明-尤溪县"},
{"address":"福建-三明-将乐县"},
{"address":"福建-三明-泰宁县"},
{"address":"福建-三明-建宁县"},
{"address":"福建-三明-永安市"},
{"address":"福建-泉州-鲤城区"},
{"address":"福建-泉州-丰泽区"},
{"address":"福建-泉州-洛江区"},
{"address":"福建-泉州-泉港区"},
{"address":"福建-泉州-惠安县"},
{"address":"福建-泉州-安溪县"},
{"address":"福建-泉州-永春县"},
{"address":"福建-泉州-德化县"},
{"address":"福建-泉州-金门县"},
{"address":"福建-泉州-石狮市"},
{"address":"福建-泉州-晋江市"},
{"address":"福建-泉州-南安市"},
{"address":"福建-漳州-芗城区"},
{"address":"福建-漳州-龙文区"},
{"address":"福建-漳州-龙海区"},
{"address":"福建-漳州-长泰区"},
{"address":"福建-漳州-云霄县"},
{"address":"福建-漳州-漳浦县"},
{"address":"福建-漳州-诏安县"},
{"address":"福建-漳州-东山县"},
{"address":"福建-漳州-南靖县"},
{"address":"福建-漳州-平和县"},
{"address":"福建-漳州-华安县"},
{"address":"福建-南平-延平区"},
{"address":"福建-南平-建阳区"},
{"address":"福建-南平-顺昌县"},
{"address":"福建-南平-浦城县"},
{"address":"福建-南平-光泽县"},
{"address":"福建-南平-松溪县"},
{"address":"福建-南平-政和县"},
{"address":"福建-南平-邵武市"},
{"address":"福建-南平-武夷山市"},
{"address":"福建-南平-建瓯市"},
{"address":"福建-龙岩-新罗区"},
{"address":"福建-龙岩-永定区"},
{"address":"福建-龙岩-长汀县"},
{"address":"福建-龙岩-上杭县"},
{"address":"福建-龙岩-武平县"},
{"address":"福建-龙岩-连城县"},
{"address":"福建-龙岩-漳平市"},
{"address":"福建-宁德-蕉城区"},
{"address":"福建-宁德-霞浦县"},
{"address":"福建-宁德-古田县"},
{"address":"福建-宁德-屏南县"},
{"address":"福建-宁德-寿宁县"},
{"address":"福建-宁德-周宁县"},
{"address":"福建-宁德-柘荣县"},
{"address":"福建-宁德-福安市"},
{"address":"福建-宁德-福鼎市"},
{"address":"江西-南昌-东湖区"},
{"address":"江西-南昌-西湖区"},
{"address":"江西-南昌-青云谱区"},
{"address":"江西-南昌-青山湖区"},
{"address":"江西-南昌-新建区"},
{"address":"江西-南昌-红谷滩区"},
{"address":"江西-南昌-南昌县"},
{"address":"江西-南昌-安义县"},
{"address":"江西-南昌-进贤县"},
{"address":"江西-景德镇-昌江区"},
{"address":"江西-景德镇-珠山区"},
{"address":"江西-景德镇-浮梁县"},
{"address":"江西-景德镇-乐平市"},
{"address":"江西-萍乡-安源区"},
{"address":"江西-萍乡-湘东区"},
{"address":"江西-萍乡-莲花县"},
{"address":"江西-萍乡-上栗县"},
{"address":"江西-萍乡-芦溪县"},
{"address":"江西-九江-濂溪区"},
{"address":"江西-九江-浔阳区"},
{"address":"江西-九江-柴桑区"},
{"address":"江西-九江-武宁县"},
{"address":"江西-九江-修水县"},
{"address":"江西-九江-永修县"},
{"address":"江西-九江-德安县"},
{"address":"江西-九江-都昌县"},
{"address":"江西-九江-湖口县"},
{"address":"江西-九江-彭泽县"},
{"address":"江西-九江-瑞昌市"},
{"address":"江西-九江-共青城市"},
{"address":"江西-九江-庐山市"},
{"address":"江西-新余-渝水区"},
{"address":"江西-新余-分宜县"},
{"address":"江西-鹰潭-月湖区"},
{"address":"江西-鹰潭-余江区"},
{"address":"江西-鹰潭-贵溪市"},
{"address":"江西-赣州-章贡区"},
{"address":"江西-赣州-南康区"},
{"address":"江西-赣州-赣县区"},
{"address":"江西-赣州-信丰县"},
{"address":"江西-赣州-大余县"},
{"address":"江西-赣州-上犹县"},
{"address":"江西-赣州-崇义县"},
{"address":"江西-赣州-安远县"},
{"address":"江西-赣州-定南县"},
{"address":"江西-赣州-全南县"},
{"address":"江西-赣州-宁都县"},
{"address":"江西-赣州-于都县"},
{"address":"江西-赣州-兴国县"},
{"address":"江西-赣州-会昌县"},
{"address":"江西-赣州-寻乌县"},
{"address":"江西-赣州-石城县"},
{"address":"江西-赣州-瑞金市"},
{"address":"江西-赣州-龙南市"},
{"address":"江西-吉安-吉州区"},
{"address":"江西-吉安-青原区"},
{"address":"江西-吉安-吉安县"},
{"address":"江西-吉安-吉水县"},
{"address":"江西-吉安-峡江县"},
{"address":"江西-吉安-新干县"},
{"address":"江西-吉安-永丰县"},
{"address":"江西-吉安-泰和县"},
{"address":"江西-吉安-遂川县"},
{"address":"江西-吉安-万安县"},
{"address":"江西-吉安-安福县"},
{"address":"江西-吉安-永新县"},
{"address":"江西-吉安-井冈山市"},
{"address":"江西-宜春-袁州区"},
{"address":"江西-宜春-奉新县"},
{"address":"江西-宜春-万载县"},
{"address":"江西-宜春-上高县"},
{"address":"江西-宜春-宜丰县"},
{"address":"江西-宜春-靖安县"},
{"address":"江西-宜春-铜鼓县"},
{"address":"江西-宜春-丰城市"},
{"address":"江西-宜春-樟树市"},
{"address":"江西-宜春-高安市"},
{"address":"江西-抚州-临川区"},
{"address":"江西-抚州-东乡区"},
{"address":"江西-抚州-南城县"},
{"address":"江西-抚州-黎川县"},
{"address":"江西-抚州-南丰县"},
{"address":"江西-抚州-崇仁县"},
{"address":"江西-抚州-乐安县"},
{"address":"江西-抚州-宜黄县"},
{"address":"江西-抚州-金溪县"},
{"address":"江西-抚州-资溪县"},
{"address":"江西-抚州-广昌县"},
{"address":"江西-上饶-信州区"},
{"address":"江西-上饶-广丰区"},
{"address":"江西-上饶-广信区"},
{"address":"江西-上饶-玉山县"},
{"address":"江西-上饶-铅山县"},
{"address":"江西-上饶-横峰县"},
{"address":"江西-上饶-弋阳县"},
{"address":"江西-上饶-余干县"},
{"address":"江西-上饶-鄱阳县"},
{"address":"江西-上饶-万年县"},
{"address":"江西-上饶-婺源县"},
{"address":"江西-上饶-德兴市"},
{"address":"山东-济南-历下区"},
{"address":"山东-济南-市中区"},
{"address":"山东-济南-槐荫区"},
{"address":"山东-济南-天桥区"},
{"address":"山东-济南-历城区"},
{"address":"山东-济南-长清区"},
{"address":"山东-济南-章丘区"},
{"address":"山东-济南-济阳区"},
{"address":"山东-济南-莱芜区"},
{"address":"山东-济南-钢城区"},
{"address":"山东-济南-平阴县"},
{"address":"山东-济南-商河县"},
{"address":"山东-青岛-市南区"},
{"address":"山东-青岛-市北区"},
{"address":"山东-青岛-黄岛区"},
{"address":"山东-青岛-崂山区"},
{"address":"山东-青岛-李沧区"},
{"address":"山东-青岛-城阳区"},
{"address":"山东-青岛-即墨区"},
{"address":"山东-青岛-胶州市"},
{"address":"山东-青岛-平度市"},
{"address":"山东-青岛-莱西市"},
{"address":"山东-淄博-淄川区"},
{"address":"山东-淄博-张店区"},
{"address":"山东-淄博-博山区"},
{"address":"山东-淄博-临淄区"},
{"address":"山东-淄博-周村区"},
{"address":"山东-淄博-桓台县"},
{"address":"山东-淄博-高青县"},
{"address":"山东-淄博-沂源县"},
{"address":"山东-枣庄-市中区"},
{"address":"山东-枣庄-薛城区"},
{"address":"山东-枣庄-峄城区"},
{"address":"山东-枣庄-台儿庄区"},
{"address":"山东-枣庄-山亭区"},
{"address":"山东-枣庄-滕州市"},
{"address":"山东-东营-东营区"},
{"address":"山东-东营-河口区"},
{"address":"山东-东营-垦利区"},
{"address":"山东-东营-利津县"},
{"address":"山东-东营-广饶县"},
{"address":"山东-烟台-芝罘区"},
{"address":"山东-烟台-福山区"},
{"address":"山东-烟台-牟平区"},
{"address":"山东-烟台-莱山区"},
{"address":"山东-烟台-蓬莱区"},
{"address":"山东-烟台-龙口市"},
{"address":"山东-烟台-莱阳市"},
{"address":"山东-烟台-莱州市"},
{"address":"山东-烟台-招远市"},
{"address":"山东-烟台-栖霞市"},
{"address":"山东-烟台-海阳市"},
{"address":"山东-潍坊-潍城区"},
{"address":"山东-潍坊-寒亭区"},
{"address":"山东-潍坊-坊子区"},
{"address":"山东-潍坊-奎文区"},
{"address":"山东-潍坊-临朐县"},
{"address":"山东-潍坊-昌乐县"},
{"address":"山东-潍坊-青州市"},
{"address":"山东-潍坊-诸城市"},
{"address":"山东-潍坊-寿光市"},
{"address":"山东-潍坊-安丘市"},
{"address":"山东-潍坊-高密市"},
{"address":"山东-潍坊-昌邑市"},
{"address":"山东-济宁-任城区"},
{"address":"山东-济宁-兖州区"},
{"address":"山东-济宁-微山县"},
{"address":"山东-济宁-鱼台县"},
{"address":"山东-济宁-金乡县"},
{"address":"山东-济宁-嘉祥县"},
{"address":"山东-济宁-汶上县"},
{"address":"山东-济宁-泗水县"},
{"address":"山东-济宁-梁山县"},
{"address":"山东-济宁-曲阜市"},
{"address":"山东-济宁-邹城市"},
{"address":"山东-泰安-泰山区"},
{"address":"山东-泰安-岱岳区"},
{"address":"山东-泰安-宁阳县"},
{"address":"山东-泰安-东平县"},
{"address":"山东-泰安-新泰市"},
{"address":"山东-泰安-肥城市"},
{"address":"山东-威海-环翠区"},
{"address":"山东-威海-文登区"},
{"address":"山东-威海-荣成市"},
{"address":"山东-威海-乳山市"},
{"address":"山东-日照-东港区"},
{"address":"山东-日照-岚山区"},
{"address":"山东-日照-五莲县"},
{"address":"山东-日照-莒县"},
{"address":"山东-临沂-兰山区"},
{"address":"山东-临沂-罗庄区"},
{"address":"山东-临沂-河东区"},
{"address":"山东-临沂-沂南县"},
{"address":"山东-临沂-郯城县"},
{"address":"山东-临沂-沂水县"},
{"address":"山东-临沂-兰陵县"},
{"address":"山东-临沂-费县"},
{"address":"山东-临沂-平邑县"},
{"address":"山东-临沂-莒南县"},
{"address":"山东-临沂-蒙阴县"},
{"address":"山东-临沂-临沭县"},
{"address":"山东-德州-德城区"},
{"address":"山东-德州-陵城区"},
{"address":"山东-德州-宁津县"},
{"address":"山东-德州-庆云县"},
{"address":"山东-德州-临邑县"},
{"address":"山东-德州-齐河县"},
{"address":"山东-德州-平原县"},
{"address":"山东-德州-夏津县"},
{"address":"山东-德州-武城县"},
{"address":"山东-德州-乐陵市"},
{"address":"山东-德州-禹城市"},
{"address":"山东-聊城-东昌府区"},
{"address":"山东-聊城-茌平区"},
{"address":"山东-聊城-阳谷县"},
{"address":"山东-聊城-莘县"},
{"address":"山东-聊城-东阿县"},
{"address":"山东-聊城-冠县"},
{"address":"山东-聊城-高唐县"},
{"address":"山东-聊城-临清市"},
{"address":"山东-滨州-滨城区"},
{"address":"山东-滨州-沾化区"},
{"address":"山东-滨州-惠民县"},
{"address":"山东-滨州-阳信县"},
{"address":"山东-滨州-无棣县"},
{"address":"山东-滨州-博兴县"},
{"address":"山东-滨州-邹平市"},
{"address":"山东-菏泽-牡丹区"},
{"address":"山东-菏泽-定陶区"},
{"address":"山东-菏泽-曹县"},
{"address":"山东-菏泽-单县"},
{"address":"山东-菏泽-成武县"},
{"address":"山东-菏泽-巨野县"},
{"address":"山东-菏泽-郓城县"},
{"address":"山东-菏泽-鄄城县"},
{"address":"山东-菏泽-东明县"},
{"address":"河南-郑州-中原区"},
{"address":"河南-郑州-二七区"},
{"address":"河南-郑州-管城回族区"},
{"address":"河南-郑州-金水区"},
{"address":"河南-郑州-上街区"},
{"address":"河南-郑州-惠济区"},
{"address":"河南-郑州-郑东新区"},
{"address":"河南-郑州-开发区"},
{"address":"河南-郑州-中牟县"},
{"address":"河南-郑州-巩义市"},
{"address":"河南-郑州-荥阳市"},
{"address":"河南-郑州-新密市"},
{"address":"河南-郑州-新郑市"},
{"address":"河南-郑州-登封市"},
{"address":"河南-开封-龙亭区"},
{"address":"河南-开封-顺河回族区"},
{"address":"河南-开封-鼓楼区"},
{"address":"河南-开封-禹王台区"},
{"address":"河南-开封-祥符区"},
{"address":"河南-开封-杞县"},
{"address":"河南-开封-通许县"},
{"address":"河南-开封-尉氏县"},
{"address":"河南-开封-兰考县"},
{"address":"河南-洛阳-老城区"},
{"address":"河南-洛阳-西工区"},
{"address":"河南-洛阳-瀍河回族区"},
{"address":"河南-洛阳-涧西区"},
{"address":"河南-洛阳-偃师区"},
{"address":"河南-洛阳-孟津区"},
{"address":"河南-洛阳-洛龙区"},
{"address":"河南-洛阳-新安县"},
{"address":"河南-洛阳-栾川县"},
{"address":"河南-洛阳-嵩县"},
{"address":"河南-洛阳-汝阳县"},
{"address":"河南-洛阳-宜阳县"},
{"address":"河南-洛阳-洛宁县"},
{"address":"河南-洛阳-伊川县"},
{"address":"河南-平顶山-新华区"},
{"address":"河南-平顶山-卫东区"},
{"address":"河南-平顶山-石龙区"},
{"address":"河南-平顶山-湛河区"},
{"address":"河南-平顶山-宝丰县"},
{"address":"河南-平顶山-叶县"},
{"address":"河南-平顶山-鲁山县"},
{"address":"河南-平顶山-郏县"},
{"address":"河南-平顶山-舞钢市"},
{"address":"河南-平顶山-汝州市"},
{"address":"河南-安阳-文峰区"},
{"address":"河南-安阳-北关区"},
{"address":"河南-安阳-殷都区"},
{"address":"河南-安阳-龙安区"},
{"address":"河南-安阳-安阳县"},
{"address":"河南-安阳-汤阴县"},
{"address":"河南-安阳-滑县"},
{"address":"河南-安阳-内黄县"},
{"address":"河南-安阳-林州市"},
{"address":"河南-鹤壁-鹤山区"},
{"address":"河南-鹤壁-山城区"},
{"address":"河南-鹤壁-淇滨区"},
{"address":"河南-鹤壁-浚县"},
{"address":"河南-鹤壁-淇县"},
{"address":"河南-新乡-红旗区"},
{"address":"河南-新乡-卫滨区"},
{"address":"河南-新乡-凤泉区"},
{"address":"河南-新乡-牧野区"},
{"address":"河南-新乡-新乡县"},
{"address":"河南-新乡-获嘉县"},
{"address":"河南-新乡-原阳县"},
{"address":"河南-新乡-延津县"},
{"address":"河南-新乡-封丘县"},
{"address":"河南-新乡-卫辉市"},
{"address":"河南-新乡-辉县市"},
{"address":"河南-新乡-长垣市"},
{"address":"河南-焦作-解放区"},
{"address":"河南-焦作-中站区"},
{"address":"河南-焦作-马村区"},
{"address":"河南-焦作-山阳区"},
{"address":"河南-焦作-修武县"},
{"address":"河南-焦作-博爱县"},
{"address":"河南-焦作-武陟县"},
{"address":"河南-焦作-温县"},
{"address":"河南-焦作-沁阳市"},
{"address":"河南-焦作-孟州市"},
{"address":"河南-濮阳-华龙区"},
{"address":"河南-濮阳-清丰县"},
{"address":"河南-濮阳-南乐县"},
{"address":"河南-濮阳-范县"},
{"address":"河南-濮阳-台前县"},
{"address":"河南-濮阳-濮阳县"},
{"address":"河南-许昌-魏都区"},
{"address":"河南-许昌-建安区"},
{"address":"河南-许昌-鄢陵县"},
{"address":"河南-许昌-襄城县"},
{"address":"河南-许昌-禹州市"},
{"address":"河南-许昌-长葛市"},
{"address":"河南-漯河-源汇区"},
{"address":"河南-漯河-郾城区"},
{"address":"河南-漯河-召陵区"},
{"address":"河南-漯河-舞阳县"},
{"address":"河南-漯河-临颍县"},
{"address":"河南-三门峡-湖滨区"},
{"address":"河南-三门峡-陕州区"},
{"address":"河南-三门峡-渑池县"},
{"address":"河南-三门峡-卢氏县"},
{"address":"河南-三门峡-义马市"},
{"address":"河南-三门峡-灵宝市"},
{"address":"河南-南阳-宛城区"},
{"address":"河南-南阳-卧龙区"},
{"address":"河南-南阳-南召县"},
{"address":"河南-南阳-方城县"},
{"address":"河南-南阳-西峡县"},
{"address":"河南-南阳-镇平县"},
{"address":"河南-南阳-内乡县"},
{"address":"河南-南阳-淅川县"},
{"address":"河南-南阳-社旗县"},
{"address":"河南-南阳-唐河县"},
{"address":"河南-南阳-新野县"},
{"address":"河南-南阳-桐柏县"},
{"address":"河南-南阳-邓州市"},
{"address":"河南-商丘-梁园区"},
{"address":"河南-商丘-睢阳区"},
{"address":"河南-商丘-民权县"},
{"address":"河南-商丘-睢县"},
{"address":"河南-商丘-宁陵县"},
{"address":"河南-商丘-柘城县"},
{"address":"河南-商丘-虞城县"},
{"address":"河南-商丘-夏邑县"},
{"address":"河南-商丘-永城市"},
{"address":"河南-信阳-浉河区"},
{"address":"河南-信阳-平桥区"},
{"address":"河南-信阳-罗山县"},
{"address":"河南-信阳-光山县"},
{"address":"河南-信阳-新县"},
{"address":"河南-信阳-商城县"},
{"address":"河南-信阳-固始县"},
{"address":"河南-信阳-潢川县"},
{"address":"河南-信阳-淮滨县"},
{"address":"河南-信阳-息县"},
{"address":"河南-周口-川汇区"},
{"address":"河南-周口-淮阳区"},
{"address":"河南-周口-扶沟县"},
{"address":"河南-周口-西华县"},
{"address":"河南-周口-商水县"},
{"address":"河南-周口-沈丘县"},
{"address":"河南-周口-郸城县"},
{"address":"河南-周口-太康县"},
{"address":"河南-周口-鹿邑县"},
{"address":"河南-周口-项城市"},
{"address":"河南-驻马店-驿城区"},
{"address":"河南-驻马店-西平县"},
{"address":"河南-驻马店-上蔡县"},
{"address":"河南-驻马店-平舆县"},
{"address":"河南-驻马店-正阳县"},
{"address":"河南-驻马店-确山县"},
{"address":"河南-驻马店-泌阳县"},
{"address":"河南-驻马店-汝南县"},
{"address":"河南-驻马店-遂平县"},
{"address":"河南-驻马店-新蔡县"},
{"address":"河南-济源-济源市"},
{"address":"湖北-武汉-江岸区"},
{"address":"湖北-武汉-江汉区"},
{"address":"湖北-武汉-硚口区"},
{"address":"湖北-武汉-汉阳区"},
{"address":"湖北-武汉-武昌区"},
{"address":"湖北-武汉-青山区"},
{"address":"湖北-武汉-洪山区"},
{"address":"湖北-武汉-东西湖区"},
{"address":"湖北-武汉-汉南区"},
{"address":"湖北-武汉-蔡甸区"},
{"address":"湖北-武汉-江夏区"},
{"address":"湖北-武汉-黄陂区"},
{"address":"湖北-武汉-新洲区"},
{"address":"湖北-黄石-黄石港区"},
{"address":"湖北-黄石-西塞山区"},
{"address":"湖北-黄石-下陆区"},
{"address":"湖北-黄石-铁山区"},
{"address":"湖北-黄石-阳新县"},
{"address":"湖北-黄石-大冶市"},
{"address":"湖北-十堰-茅箭区"},
{"address":"湖北-十堰-张湾区"},
{"address":"湖北-十堰-郧阳区"},
{"address":"湖北-十堰-郧西县"},
{"address":"湖北-十堰-竹山县"},
{"address":"湖北-十堰-竹溪县"},
{"address":"湖北-十堰-房县"},
{"address":"湖北-十堰-丹江口市"},
{"address":"湖北-宜昌-西陵区"},
{"address":"湖北-宜昌-伍家岗区"},
{"address":"湖北-宜昌-点军区"},
{"address":"湖北-宜昌-猇亭区"},
{"address":"湖北-宜昌-夷陵区"},
{"address":"湖北-宜昌-远安县"},
{"address":"湖北-宜昌-兴山县"},
{"address":"湖北-宜昌-秭归县"},
{"address":"湖北-宜昌-长阳土家族自治县"},
{"address":"湖北-宜昌-五峰土家族自治县"},
{"address":"湖北-宜昌-宜都市"},
{"address":"湖北-宜昌-当阳市"},
{"address":"湖北-宜昌-枝江市"},
{"address":"湖北-襄阳-襄城区"},
{"address":"湖北-襄阳-樊城区"},
{"address":"湖北-襄阳-襄州区"},
{"address":"湖北-襄阳-南漳县"},
{"address":"湖北-襄阳-谷城县"},
{"address":"湖北-襄阳-保康县"},
{"address":"湖北-襄阳-老河口市"},
{"address":"湖北-襄阳-枣阳市"},
{"address":"湖北-襄阳-宜城市"},
{"address":"湖北-鄂州-梁子湖区"},
{"address":"湖北-鄂州-华容区"},
{"address":"湖北-鄂州-鄂城区"},
{"address":"湖北-荆门-东宝区"},
{"address":"湖北-荆门-掇刀区"},
{"address":"湖北-荆门-沙洋县"},
{"address":"湖北-荆门-钟祥市"},
{"address":"湖北-荆门-京山市"},
{"address":"湖北-孝感-孝南区"},
{"address":"湖北-孝感-孝昌县"},
{"address":"湖北-孝感-大悟县"},
{"address":"湖北-孝感-云梦县"},
{"address":"湖北-孝感-应城市"},
{"address":"湖北-孝感-安陆市"},
{"address":"湖北-孝感-汉川市"},
{"address":"湖北-荆州-沙市区"},
{"address":"湖北-荆州-荆州区"},
{"address":"湖北-荆州-公安县"},
{"address":"湖北-荆州-江陵县"},
{"address":"湖北-荆州-石首市"},
{"address":"湖北-荆州-洪湖市"},
{"address":"湖北-荆州-松滋市"},
{"address":"湖北-荆州-监利市"},
{"address":"湖北-黄冈-黄州区"},
{"address":"湖北-黄冈-团风县"},
{"address":"湖北-黄冈-红安县"},
{"address":"湖北-黄冈-罗田县"},
{"address":"湖北-黄冈-英山县"},
{"address":"湖北-黄冈-浠水县"},
{"address":"湖北-黄冈-蕲春县"},
{"address":"湖北-黄冈-黄梅县"},
{"address":"湖北-黄冈-麻城市"},
{"address":"湖北-黄冈-武穴市"},
{"address":"湖北-咸宁-咸安区"},
{"address":"湖北-咸宁-嘉鱼县"},
{"address":"湖北-咸宁-通城县"},
{"address":"湖北-咸宁-崇阳县"},
{"address":"湖北-咸宁-通山县"},
{"address":"湖北-咸宁-赤壁市"},
{"address":"湖北-随州-曾都区"},
{"address":"湖北-随州-随县"},
{"address":"湖北-随州-广水市"},
{"address":"湖北-恩施-恩施市"},
{"address":"湖北-恩施-利川市"},
{"address":"湖北-恩施-建始县"},
{"address":"湖北-恩施-巴东县"},
{"address":"湖北-恩施-宣恩县"},
{"address":"湖北-恩施-咸丰县"},
{"address":"湖北-恩施-来凤县"},
{"address":"湖北-恩施-鹤峰县"},
{"address":"湖北-仙桃-仙桃市"},
{"address":"湖北-潜江-潜江市"},
{"address":"湖北-天门-天门市"},
{"address":"湖北-神农架-神农架林区"},
{"address":"湖南-长沙-长沙市"},
{"address":"湖南-长沙-芙蓉区"},
{"address":"湖南-长沙-天心区"},
{"address":"湖南-长沙-岳麓区"},
{"address":"湖南-长沙-开福区"},
{"address":"湖南-长沙-雨花区"},
{"address":"湖南-长沙-望城区"},
{"address":"湖南-长沙-长沙县"},
{"address":"湖南-长沙-浏阳市"},
{"address":"湖南-长沙-宁乡市"},
{"address":"湖南-株洲-荷塘区"},
{"address":"湖南-株洲-芦淞区"},
{"address":"湖南-株洲-石峰区"},
{"address":"湖南-株洲-天元区"},
{"address":"湖南-株洲-渌口区"},
{"address":"湖南-株洲-攸县"},
{"address":"湖南-株洲-茶陵县"},
{"address":"湖南-株洲-炎陵县"},
{"address":"湖南-株洲-醴陵市"},
{"address":"湖南-湘潭-雨湖区"},
{"address":"湖南-湘潭-岳塘区"},
{"address":"湖南-湘潭-湘潭县"},
{"address":"湖南-湘潭-湘乡市"},
{"address":"湖南-湘潭-韶山市"},
{"address":"湖南-衡阳-珠晖区"},
{"address":"湖南-衡阳-雁峰区"},
{"address":"湖南-衡阳-石鼓区"},
{"address":"湖南-衡阳-蒸湘区"},
{"address":"湖南-衡阳-南岳区"},
{"address":"湖南-衡阳-衡阳县"},
{"address":"湖南-衡阳-衡南县"},
{"address":"湖南-衡阳-衡山县"},
{"address":"湖南-衡阳-衡东县"},
{"address":"湖南-衡阳-祁东县"},
{"address":"湖南-衡阳-耒阳市"},
{"address":"湖南-衡阳-常宁市"},
{"address":"湖南-邵阳-双清区"},
{"address":"湖南-邵阳-大祥区"},
{"address":"湖南-邵阳-北塔区"},
{"address":"湖南-邵阳-新邵县"},
{"address":"湖南-邵阳-邵阳县"},
{"address":"湖南-邵阳-隆回县"},
{"address":"湖南-邵阳-洞口县"},
{"address":"湖南-邵阳-绥宁县"},
{"address":"湖南-邵阳-新宁县"},
{"address":"湖南-邵阳-苗族自治县"},
{"address":"湖南-邵阳-武冈市"},
{"address":"湖南-邵阳-邵东市"},
{"address":"湖南-岳阳-岳阳楼区"},
{"address":"湖南-岳阳-云溪区"},
{"address":"湖南-岳阳-君山区"},
{"address":"湖南-岳阳-岳阳县"},
{"address":"湖南-岳阳-华容县"},
{"address":"湖南-岳阳-湘阴县"},
{"address":"湖南-岳阳-平江县"},
{"address":"湖南-岳阳-汨罗市"},
{"address":"湖南-岳阳-临湘市"},
{"address":"湖南-常德-武陵区"},
{"address":"湖南-常德-鼎城区"},
{"address":"湖南-常德-安乡县"},
{"address":"湖南-常德-汉寿县"},
{"address":"湖南-常德-澧县"},
{"address":"湖南-常德-临澧县"},
{"address":"湖南-常德-桃源县"},
{"address":"湖南-常德-石门县"},
{"address":"湖南-常德-津市市"},
{"address":"湖南-张家界-永定区"},
{"address":"湖南-张家界-武陵源区"},
{"address":"湖南-张家界-慈利县"},
{"address":"湖南-张家界-桑植县"},
{"address":"湖南-益阳-资阳区"},
{"address":"湖南-益阳-赫山区"},
{"address":"湖南-益阳-南县"},
{"address":"湖南-益阳-桃江县"},
{"address":"湖南-益阳-安化县"},
{"address":"湖南-益阳-沅江市"},
{"address":"湖南-郴州-北湖区"},
{"address":"湖南-郴州-苏仙区"},
{"address":"湖南-郴州-桂阳县"},
{"address":"湖南-郴州-宜章县"},
{"address":"湖南-郴州-永兴县"},
{"address":"湖南-郴州-嘉禾县"},
{"address":"湖南-郴州-临武县"},
{"address":"湖南-郴州-汝城县"},
{"address":"湖南-郴州-桂东县"},
{"address":"湖南-郴州-安仁县"},
{"address":"湖南-郴州-资兴市"},
{"address":"湖南-永州-零陵区"},
{"address":"湖南-永州-冷水滩区"},
{"address":"湖南-永州-东安县"},
{"address":"湖南-永州-双牌县"},
{"address":"湖南-永州-道县"},
{"address":"湖南-永州-江永县"},
{"address":"湖南-永州-宁远县"},
{"address":"湖南-永州-蓝山县"},
{"address":"湖南-永州-新田县"},
{"address":"湖南-永州-江华瑶族自治县"},
{"address":"湖南-永州-祁阳市"},
{"address":"湖南-怀化-怀化市"},
{"address":"湖南-怀化-鹤城区"},
{"address":"湖南-怀化-中方县"},
{"address":"湖南-怀化-沅陵县"},
{"address":"湖南-怀化-辰溪县"},
{"address":"湖南-怀化-溆浦县"},
{"address":"湖南-怀化-会同县"},
{"address":"湖南-怀化-麻阳苗族自治县"},
{"address":"湖南-怀化-新晃侗族自治县"},
{"address":"湖南-怀化-芷江侗族自治县"},
{"address":"湖南-怀化-靖州苗族侗族自治县"},
{"address":"湖南-怀化-通道侗族自治县"},
{"address":"湖南-怀化-洪江市"},
{"address":"湖南-娄底-娄星区"},
{"address":"湖南-娄底-双峰县"},
{"address":"湖南-娄底-新化县"},
{"address":"湖南-娄底-冷水江市"},
{"address":"湖南-娄底-涟源市"},
{"address":"湖南-湘西-吉首市"},
{"address":"湖南-湘西-泸溪县"},
{"address":"湖南-湘西-凤凰县"},
{"address":"湖南-湘西-花垣县"},
{"address":"湖南-湘西-保靖县"},
{"address":"湖南-湘西-古丈县"},
{"address":"湖南-湘西-永顺县"},
{"address":"湖南-湘西-龙山县"},
{"address":"广东-广州-荔湾区"},
{"address":"广东-广州-越秀区"},
{"address":"广东-广州-海珠区"},
{"address":"广东-广州-天河区"},
{"address":"广东-广州-白云区"},
{"address":"广东-广州-黄埔区"},
{"address":"广东-广州-番禺区"},
{"address":"广东-广州-花都区"},
{"address":"广东-广州-南沙区"},
{"address":"广东-广州-从化区"},
{"address":"广东-广州-增城区"},
{"address":"广东-韶关-武江区"},
{"address":"广东-韶关-浈江区"},
{"address":"广东-韶关-曲江区"},
{"address":"广东-韶关-始兴县"},
{"address":"广东-韶关-仁化县"},
{"address":"广东-韶关-翁源县"},
{"address":"广东-韶关-瑶族自治县"},
{"address":"广东-韶关-新丰县"},
{"address":"广东-韶关-乐昌市"},
{"address":"广东-韶关-南雄市"},
{"address":"广东-深圳-罗湖区"},
{"address":"广东-深圳-福田区"},
{"address":"广东-深圳-南山区"},
{"address":"广东-深圳-宝安区"},
{"address":"广东-深圳-龙岗区"},
{"address":"广东-深圳-盐田区"},
{"address":"广东-深圳-龙华区"},
{"address":"广东-深圳-坪山区"},
{"address":"广东-深圳-光明区"},
{"address":"广东-珠海-香洲区"},
{"address":"广东-珠海-斗门区"},
{"address":"广东-珠海-金湾区"},
{"address":"广东-汕头-龙湖区"},
{"address":"广东-汕头-金平区"},
{"address":"广东-汕头-濠江区"},
{"address":"广东-汕头-潮阳区"},
{"address":"广东-汕头-潮南区"},
{"address":"广东-汕头-澄海区"},
{"address":"广东-汕头-南澳县"},
{"address":"广东-佛山-禅城区"},
{"address":"广东-佛山-南海区"},
{"address":"广东-佛山-顺德区"},
{"address":"广东-佛山-三水区"},
{"address":"广东-佛山-高明区"},
{"address":"广东-江门-蓬江区"},
{"address":"广东-江门-江海区"},
{"address":"广东-江门-新会区"},
{"address":"广东-江门-台山市"},
{"address":"广东-江门-开平市"},
{"address":"广东-江门-鹤山市"},
{"address":"广东-江门-恩平市"},
{"address":"广东-湛江-赤坎区"},
{"address":"广东-湛江-霞山区"},
{"address":"广东-湛江-坡头区"},
{"address":"广东-湛江-麻章区"},
{"address":"广东-湛江-遂溪县"},
{"address":"广东-湛江-徐闻县"},
{"address":"广东-湛江-廉江市"},
{"address":"广东-湛江-雷州市"},
{"address":"广东-湛江-吴川市"},
{"address":"广东-茂名-茂南区"},
{"address":"广东-茂名-电白区"},
{"address":"广东-茂名-高州市"},
{"address":"广东-茂名-化州市"},
{"address":"广东-茂名-信宜市"},
{"address":"广东-肇庆-端州区"},
{"address":"广东-肇庆-鼎湖区"},
{"address":"广东-肇庆-高要区"},
{"address":"广东-肇庆-广宁县"},
{"address":"广东-肇庆-怀集县"},
{"address":"广东-肇庆-封开县"},
{"address":"广东-肇庆-德庆县"},
{"address":"广东-肇庆-四会市"},
{"address":"广东-惠州-惠城区"},
{"address":"广东-惠州-惠阳区"},
{"address":"广东-惠州-博罗县"},
{"address":"广东-惠州-惠东县"},
{"address":"广东-惠州-龙门县"},
{"address":"广东-梅州-梅江区"},
{"address":"广东-梅州-梅县区"},
{"address":"广东-梅州-大埔县"},
{"address":"广东-梅州-丰顺县"},
{"address":"广东-梅州-五华县"},
{"address":"广东-梅州-平远县"},
{"address":"广东-梅州-蕉岭县"},
{"address":"广东-梅州-兴宁市"},
{"address":"广东-汕尾-城区"},
{"address":"广东-汕尾-海丰县"},
{"address":"广东-汕尾-陆河县"},
{"address":"广东-汕尾-陆丰市"},
{"address":"广东-河源-源城区"},
{"address":"广东-河源-紫金县"},
{"address":"广东-河源-龙川县"},
{"address":"广东-河源-连平县"},
{"address":"广东-河源-和平县"},
{"address":"广东-河源-东源县"},
{"address":"广东-阳江-江城区"},
{"address":"广东-阳江-阳东区"},
{"address":"广东-阳江-阳西县"},
{"address":"广东-阳江-阳春市"},
{"address":"广东-清远-清城区"},
{"address":"广东-清远-清新区"},
{"address":"广东-清远-佛冈县"},
{"address":"广东-清远-阳山县"},
{"address":"广东-清远-瑶族自治县"},
{"address":"广东-清远-英德市"},
{"address":"广东-清远-连州市"},
{"address":"广东-东莞-东莞市"},
{"address":"广东-中山-中山市"},
{"address":"广东-潮州-湘桥区"},
{"address":"广东-潮州-潮安区"},
{"address":"广东-潮州-饶平县"},
{"address":"广东-揭阳-榕城区"},
{"address":"广东-揭阳-揭东区"},
{"address":"广东-揭阳-揭西县"},
{"address":"广东-揭阳-惠来县"},
{"address":"广东-揭阳-普宁市"},
{"address":"广东-云浮-云城区"},
{"address":"广东-云浮-云安区"},
{"address":"广东-云浮-新兴县"},
{"address":"广东-云浮-郁南县"},
{"address":"广东-云浮-罗定市"},
{"address":"广西-南宁-兴宁区"},
{"address":"广西-南宁-青秀区"},
{"address":"广西-南宁-江南区"},
{"address":"广西-南宁-西乡塘区"},
{"address":"广西-南宁-良庆区"},
{"address":"广西-南宁-邕宁区"},
{"address":"广西-南宁-武鸣区"},
{"address":"广西-南宁-隆安县"},
{"address":"广西-南宁-马山县"},
{"address":"广西-南宁-上林县"},
{"address":"广西-南宁-宾阳县"},
{"address":"广西-南宁-横州市"},
{"address":"广西-柳州-城中区"},
{"address":"广西-柳州-鱼峰区"},
{"address":"广西-柳州-柳南区"},
{"address":"广西-柳州-柳北区"},
{"address":"广西-柳州-柳江区"},
{"address":"广西-柳州-柳城县"},
{"address":"广西-柳州-鹿寨县"},
{"address":"广西-柳州-融安县"},
{"address":"广西-柳州-融水苗族自治县"},
{"address":"广西-柳州-三江侗族自治县"},
{"address":"广西-桂林-秀峰区"},
{"address":"广西-桂林-叠彩区"},
{"address":"广西-桂林-象山区"},
{"address":"广西-桂林-七星区"},
{"address":"广西-桂林-雁山区"},
{"address":"广西-桂林-临桂区"},
{"address":"广西-桂林-阳朔县"},
{"address":"广西-桂林-灵川县"},
{"address":"广西-桂林-全州县"},
{"address":"广西-桂林-兴安县"},
{"address":"广西-桂林-永福县"},
{"address":"广西-桂林-灌阳县"},
{"address":"广西-桂林-龙胜各族自治县"},
{"address":"广西-桂林-资源县"},
{"address":"广西-桂林-平乐县"},
{"address":"广西-桂林-恭城瑶族自治县"},
{"address":"广西-桂林-荔浦市"},
{"address":"广西-梧州-万秀区"},
{"address":"广西-梧州-长洲区"},
{"address":"广西-梧州-龙圩区"},
{"address":"广西-梧州-苍梧县"},
{"address":"广西-梧州-藤县"},
{"address":"广西-梧州-蒙山县"},
{"address":"广西-梧州-岑溪市"},
{"address":"广西-北海-海城区"},
{"address":"广西-北海-银海区"},
{"address":"广西-北海-铁山港区"},
{"address":"广西-北海-合浦县"},
{"address":"广西-防城港-港口区"},
{"address":"广西-防城港-防城区"},
{"address":"广西-防城港-上思县"},
{"address":"广西-防城港-东兴市"},
{"address":"广西-钦州-钦州市"},
{"address":"广西-钦州-钦南区"},
{"address":"广西-钦州-钦北区"},
{"address":"广西-钦州-灵山县"},
{"address":"广西-钦州-浦北县"},
{"address":"广西-贵港-港北区"},
{"address":"广西-贵港-港南区"},
{"address":"广西-贵港-覃塘区"},
{"address":"广西-贵港-平南县"},
{"address":"广西-贵港-桂平市"},
{"address":"广西-玉林-玉州区"},
{"address":"广西-玉林-福绵区"},
{"address":"广西-玉林-容县"},
{"address":"广西-玉林-陆川县"},
{"address":"广西-玉林-博白县"},
{"address":"广西-玉林-兴业县"},
{"address":"广西-玉林-北流市"},
{"address":"广西-百色-右江区"},
{"address":"广西-百色-田阳区"},
{"address":"广西-百色-田东县"},
{"address":"广西-百色-德保县"},
{"address":"广西-百色-那坡县"},
{"address":"广西-百色-凌云县"},
{"address":"广西-百色-乐业县"},
{"address":"广西-百色-田林县"},
{"address":"广西-百色-西林县"},
{"address":"广西-百色-隆林各族自治县"},
{"address":"广西-百色-靖西市"},
{"address":"广西-百色-平果市"},
{"address":"广西-贺州-八步区"},
{"address":"广西-贺州-平桂区"},
{"address":"广西-贺州-昭平县"},
{"address":"广西-贺州-钟山县"},
{"address":"广西-贺州-富川瑶族自治县"},
{"address":"广西-河池-金城江区"},
{"address":"广西-河池-宜州区"},
{"address":"广西-河池-南丹县"},
{"address":"广西-河池-天峨县"},
{"address":"广西-河池-凤山县"},
{"address":"广西-河池-东兰县"},
{"address":"广西-河池-罗城仫佬族自治县"},
{"address":"广西-河池-环江毛南族自治县"},
{"address":"广西-河池-巴马瑶族自治县"},
{"address":"广西-河池-都安瑶族自治县"},
{"address":"广西-河池-大化瑶族自治县"},
{"address":"广西-来宾-兴宾区"},
{"address":"广西-来宾-忻城县"},
{"address":"广西-来宾-象州县"},
{"address":"广西-来宾-武宣县"},
{"address":"广西-来宾-金秀瑶族自治县"},
{"address":"广西-来宾-合山市"},
{"address":"广西-崇左-江州区"},
{"address":"广西-崇左-扶绥县"},
{"address":"广西-崇左-宁明县"},
{"address":"广西-崇左-龙州县"},
{"address":"广西-崇左-大新县"},
{"address":"广西-崇左-天等县"},
{"address":"广西-崇左-凭祥市"},
{"address":"海南-海口-秀英区"},
{"address":"海南-海口-龙华区"},
{"address":"海南-海口-琼山区"},
{"address":"海南-海口-美兰区"},
{"address":"海南-三亚-海棠区"},
{"address":"海南-三亚-吉阳区"},
{"address":"海南-三亚-天涯区"},
{"address":"海南-三亚-崖州区"},
{"address":"海南-三沙-西沙区"},
{"address":"海南-三沙-南沙区"},
{"address":"海南-儋州-儋州市"},
{"address":"海南-五指山市-五指山市"},
{"address":"海南-琼海-琼海市"},
{"address":"海南-文昌-文昌市"},
{"address":"海南-万宁-万宁市"},
{"address":"海南-东方-东方市"},
{"address":"海南-定安-定安县"},
{"address":"海南-屯昌-屯昌县"},
{"address":"海南-澄迈-澄迈县"},
{"address":"海南-临高-临高县"},
{"address":"海南-白沙-白沙黎族自治县"},
{"address":"海南-昌江-昌江黎族自治县"},
{"address":"海南-乐东-乐东黎族自治县"},
{"address":"海南-陵水-陵水黎族自治县"},
{"address":"海南-保亭-保亭黎族苗族自治县"},
{"address":"海南-琼中-琼中黎族苗族自治县"},
{"address":"重庆-重庆-重庆城区"},
{"address":"重庆-重庆-万州区"},
{"address":"重庆-重庆-涪陵区"},
{"address":"重庆-重庆-渝中区"},
{"address":"重庆-重庆-大渡口区"},
{"address":"重庆-重庆-江北区"},
{"address":"重庆-重庆-沙坪坝区"},
{"address":"重庆-重庆-九龙坡区"},
{"address":"重庆-重庆-南岸区"},
{"address":"重庆-重庆-北碚区"},
{"address":"重庆-重庆-綦江区"},
{"address":"重庆-重庆-大足区"},
{"address":"重庆-重庆-渝北区"},
{"address":"重庆-重庆-巴南区"},
{"address":"重庆-重庆-黔江区"},
{"address":"重庆-重庆-长寿区"},
{"address":"重庆-重庆-江津区"},
{"address":"重庆-重庆-合川区"},
{"address":"重庆-重庆-永川区"},
{"address":"重庆-重庆-南川区"},
{"address":"重庆-重庆-璧山区"},
{"address":"重庆-重庆-铜梁区"},
{"address":"重庆-重庆-潼南区"},
{"address":"重庆-重庆-荣昌区"},
{"address":"重庆-重庆-开州区"},
{"address":"重庆-重庆-梁平区"},
{"address":"重庆-重庆-武隆区"},
{"address":"重庆-重庆-重庆郊县"},
{"address":"重庆-重庆-城口县"},
{"address":"重庆-重庆-丰都县"},
{"address":"重庆-重庆-垫江县"},
{"address":"重庆-重庆-忠县"},
{"address":"重庆-重庆-云阳县"},
{"address":"重庆-重庆-奉节县"},
{"address":"重庆-重庆-巫山县"},
{"address":"重庆-重庆-巫溪县"},
{"address":"重庆-重庆-石柱土家族自治县"},
{"address":"重庆-重庆-秀山土家族苗族自治县"},
{"address":"重庆-重庆-酉阳土家族苗族自治县"},
{"address":"重庆-重庆-彭水苗族土家族自治县"},
{"address":"四川-成都-锦江区"},
{"address":"四川-成都-青羊区"},
{"address":"四川-成都-金牛区"},
{"address":"四川-成都-武侯区"},
{"address":"四川-成都-成华区"},
{"address":"四川-成都-龙泉驿区"},
{"address":"四川-成都-青白江区"},
{"address":"四川-成都-新都区"},
{"address":"四川-成都-温江区"},
{"address":"四川-成都-双流区"},
{"address":"四川-成都-郫都区"},
{"address":"四川-成都-新津区"},
{"address":"四川-成都-金堂县"},
{"address":"四川-成都-大邑县"},
{"address":"四川-成都-蒲江县"},
{"address":"四川-成都-都江堰市"},
{"address":"四川-成都-彭州市"},
{"address":"四川-成都-邛崃市"},
{"address":"四川-成都-崇州市"},
{"address":"四川-成都-简阳市"},
{"address":"四川-自贡-自流井区"},
{"address":"四川-自贡-贡井区"},
{"address":"四川-自贡-大安区"},
{"address":"四川-自贡-沿滩区"},
{"address":"四川-自贡-荣县"},
{"address":"四川-自贡-富顺县"},
{"address":"四川-攀枝花-东区"},
{"address":"四川-攀枝花-西区"},
{"address":"四川-攀枝花-仁和区"},
{"address":"四川-攀枝花-米易县"},
{"address":"四川-攀枝花-盐边县"},
{"address":"四川-泸州-江阳区"},
{"address":"四川-泸州-纳溪区"},
{"address":"四川-泸州-龙马潭区"},
{"address":"四川-泸州-泸县"},
{"address":"四川-泸州-合江县"},
{"address":"四川-泸州-叙永县"},
{"address":"四川-泸州-古蔺县"},
{"address":"四川-德阳-旌阳区"},
{"address":"四川-德阳-罗江区"},
{"address":"四川-德阳-中江县"},
{"address":"四川-德阳-广汉市"},
{"address":"四川-德阳-什邡市"},
{"address":"四川-德阳-绵竹市"},
{"address":"四川-绵阳-涪城区"},
{"address":"四川-绵阳-游仙区"},
{"address":"四川-绵阳-安州区"},
{"address":"四川-绵阳-三台县"},
{"address":"四川-绵阳-盐亭县"},
{"address":"四川-绵阳-梓潼县"},
{"address":"四川-绵阳-北川羌族自治县"},
{"address":"四川-绵阳-平武县"},
{"address":"四川-绵阳-江油市"},
{"address":"四川-广元-利州区"},
{"address":"四川-广元-昭化区"},
{"address":"四川-广元-朝天区"},
{"address":"四川-广元-旺苍县"},
{"address":"四川-广元-青川县"},
{"address":"四川-广元-剑阁县"},
{"address":"四川-广元-苍溪县"},
{"address":"四川-遂宁-船山区"},
{"address":"四川-遂宁-安居区"},
{"address":"四川-遂宁-蓬溪县"},
{"address":"四川-遂宁-大英县"},
{"address":"四川-遂宁-射洪市"},
{"address":"四川-内江-市中区"},
{"address":"四川-内江-东兴区"},
{"address":"四川-内江-威远县"},
{"address":"四川-内江-资中县"},
{"address":"四川-内江-隆昌市"},
{"address":"四川-乐山-市中区"},
{"address":"四川-乐山-沙湾区"},
{"address":"四川-乐山-五通桥区"},
{"address":"四川-乐山-金口河区"},
{"address":"四川-乐山-犍为县"},
{"address":"四川-乐山-井研县"},
{"address":"四川-乐山-夹江县"},
{"address":"四川-乐山-沐川县"},
{"address":"四川-乐山-峨边彝族自治县"},
{"address":"四川-乐山-马边彝族自治县"},
{"address":"四川-乐山-峨眉山市"},
{"address":"四川-南充-顺庆区"},
{"address":"四川-南充-高坪区"},
{"address":"四川-南充-嘉陵区"},
{"address":"四川-南充-南部县"},
{"address":"四川-南充-营山县"},
{"address":"四川-南充-蓬安县"},
{"address":"四川-南充-仪陇县"},
{"address":"四川-南充-西充县"},
{"address":"四川-南充-阆中市"},
{"address":"四川-眉山-东坡区"},
{"address":"四川-眉山-彭山区"},
{"address":"四川-眉山-仁寿县"},
{"address":"四川-眉山-洪雅县"},
{"address":"四川-眉山-丹棱县"},
{"address":"四川-眉山-青神县"},
{"address":"四川-宜宾-翠屏区"},
{"address":"四川-宜宾-南溪区"},
{"address":"四川-宜宾-叙州区"},
{"address":"四川-宜宾-江安县"},
{"address":"四川-宜宾-长宁县"},
{"address":"四川-宜宾-高县"},
{"address":"四川-宜宾-珙县"},
{"address":"四川-宜宾-筠连县"},
{"address":"四川-宜宾-兴文县"},
{"address":"四川-宜宾-屏山县"},
{"address":"四川-广安-广安区"},
{"address":"四川-广安-前锋区"},
{"address":"四川-广安-岳池县"},
{"address":"四川-广安-武胜县"},
{"address":"四川-广安-邻水县"},
{"address":"四川-广安-华蓥市"},
{"address":"四川-达州-通川区"},
{"address":"四川-达州-达川区"},
{"address":"四川-达州-宣汉县"},
{"address":"四川-达州-开江县"},
{"address":"四川-达州-大竹县"},
{"address":"四川-达州-渠县"},
{"address":"四川-达州-万源市"},
{"address":"四川-雅安-雨城区"},
{"address":"四川-雅安-名山区"},
{"address":"四川-雅安-荥经县"},
{"address":"四川-雅安-汉源县"},
{"address":"四川-雅安-石棉县"},
{"address":"四川-雅安-天全县"},
{"address":"四川-雅安-芦山县"},
{"address":"四川-雅安-宝兴县"},
{"address":"四川-巴中-巴州区"},
{"address":"四川-巴中-恩阳区"},
{"address":"四川-巴中-通江县"},
{"address":"四川-巴中-南江县"},
{"address":"四川-巴中-平昌县"},
{"address":"四川-资阳-雁江区"},
{"address":"四川-资阳-安岳县"},
{"address":"四川-资阳-乐至县"},
{"address":"四川-阿坝-阿坝藏族羌族自治州"},
{"address":"四川-阿坝-马尔康市"},
{"address":"四川-阿坝-汶川县"},
{"address":"四川-阿坝-理县"},
{"address":"四川-阿坝-茂县"},
{"address":"四川-阿坝-松潘县"},
{"address":"四川-阿坝-九寨沟县"},
{"address":"四川-阿坝-金川县"},
{"address":"四川-阿坝-小金县"},
{"address":"四川-阿坝-黑水县"},
{"address":"四川-阿坝-壤塘县"},
{"address":"四川-阿坝-阿坝县"},
{"address":"四川-阿坝-若尔盖县"},
{"address":"四川-阿坝-红原县"},
{"address":"四川-甘孜-康定市"},
{"address":"四川-甘孜-泸定县"},
{"address":"四川-甘孜-丹巴县"},
{"address":"四川-甘孜-九龙县"},
{"address":"四川-甘孜-雅江县"},
{"address":"四川-甘孜-道孚县"},
{"address":"四川-甘孜-炉霍县"},
{"address":"四川-甘孜-甘孜县"},
{"address":"四川-甘孜-新龙县"},
{"address":"四川-甘孜-德格县"},
{"address":"四川-甘孜-白玉县"},
{"address":"四川-甘孜-石渠县"},
{"address":"四川-甘孜-色达县"},
{"address":"四川-甘孜-理塘县"},
{"address":"四川-甘孜-巴塘县"},
{"address":"四川-甘孜-乡城县"},
{"address":"四川-甘孜-稻城县"},
{"address":"四川-甘孜-得荣县"},
{"address":"四川-凉山-西昌市"},
{"address":"四川-凉山-会理市"},
{"address":"四川-凉山-木里藏族自治县"},
{"address":"四川-凉山-盐源县"},
{"address":"四川-凉山-德昌县"},
{"address":"四川-凉山-会东县"},
{"address":"四川-凉山-宁南县"},
{"address":"四川-凉山-普格县"},
{"address":"四川-凉山-布拖县"},
{"address":"四川-凉山-金阳县"},
{"address":"四川-凉山-昭觉县"},
{"address":"四川-凉山-喜德县"},
{"address":"四川-凉山-冕宁县"},
{"address":"四川-凉山-越西县"},
{"address":"四川-凉山-甘洛县"},
{"address":"四川-凉山-美姑县"},
{"address":"四川-凉山-雷波县"},
{"address":"贵州-贵阳-南明区"},
{"address":"贵州-贵阳-云岩区"},
{"address":"贵州-贵阳-花溪区"},
{"address":"贵州-贵阳-乌当区"},
{"address":"贵州-贵阳-白云区"},
{"address":"贵州-贵阳-观山湖区"},
{"address":"贵州-贵阳-开阳县"},
{"address":"贵州-贵阳-息烽县"},
{"address":"贵州-贵阳-修文县"},
{"address":"贵州-贵阳-清镇市"},
{"address":"贵州-六盘水-钟山区"},
{"address":"贵州-六盘水-六枝特区"},
{"address":"贵州-六盘水-水城区"},
{"address":"贵州-六盘水-盘州市"},
{"address":"贵州-遵义-红花岗区"},
{"address":"贵州-遵义-汇川区"},
{"address":"贵州-遵义-播州区"},
{"address":"贵州-遵义-桐梓县"},
{"address":"贵州-遵义-绥阳县"},
{"address":"贵州-遵义-正安县"},
{"address":"贵州-遵义-道真仡佬族苗族自治县"},
{"address":"贵州-遵义-务川仡佬族苗族自治县"},
{"address":"贵州-遵义-凤冈县"},
{"address":"贵州-遵义-湄潭县"},
{"address":"贵州-遵义-余庆县"},
{"address":"贵州-遵义-习水县"},
{"address":"贵州-遵义-赤水市"},
{"address":"贵州-遵义-仁怀市"},
{"address":"贵州-安顺-西秀区"},
{"address":"贵州-安顺-平坝区"},
{"address":"贵州-安顺-普定县"},
{"address":"贵州-安顺-镇宁布依族苗族自治县"},
{"address":"贵州-安顺-关岭布依族苗族自治县"},
{"address":"贵州-安顺-紫云苗族布依族自治县"},
{"address":"贵州-毕节-七星关区"},
{"address":"贵州-毕节-大方县"},
{"address":"贵州-毕节-金沙县"},
{"address":"贵州-毕节-织金县"},
{"address":"贵州-毕节-纳雍县"},
{"address":"贵州-毕节-威宁彝族回族苗族自治县"},
{"address":"贵州-毕节-赫章县"},
{"address":"贵州-毕节-黔西市"},
{"address":"贵州-铜仁-铜仁市"},
{"address":"贵州-铜仁-碧江区"},
{"address":"贵州-铜仁-万山区"},
{"address":"贵州-铜仁-江口县"},
{"address":"贵州-铜仁-玉屏侗族自治县"},
{"address":"贵州-铜仁-石阡县"},
{"address":"贵州-铜仁-思南县"},
{"address":"贵州-铜仁-印江土家族苗族自治县"},
{"address":"贵州-铜仁-德江县"},
{"address":"贵州-铜仁-沿河土家族自治县"},
{"address":"贵州-铜仁-松桃苗族自治县"},
{"address":"贵州-黔西南-兴义市"},
{"address":"贵州-黔西南-兴仁市"},
{"address":"贵州-黔西南-普安县"},
{"address":"贵州-黔西南-晴隆县"},
{"address":"贵州-黔西南-贞丰县"},
{"address":"贵州-黔西南-望谟县"},
{"address":"贵州-黔西南-册亨县"},
{"address":"贵州-黔西南-安龙县"},
{"address":"贵州-黔东南-凯里市"},
{"address":"贵州-黔东南-黄平县"},
{"address":"贵州-黔东南-施秉县"},
{"address":"贵州-黔东南-三穗县"},
{"address":"贵州-黔东南-镇远县"},
{"address":"贵州-黔东南-岑巩县"},
{"address":"贵州-黔东南-天柱县"},
{"address":"贵州-黔东南-锦屏县"},
{"address":"贵州-黔东南-剑河县"},
{"address":"贵州-黔东南-台江县"},
{"address":"贵州-黔东南-黎平县"},
{"address":"贵州-黔东南-榕江县"},
{"address":"贵州-黔东南-从江县"},
{"address":"贵州-黔东南-雷山县"},
{"address":"贵州-黔东南-麻江县"},
{"address":"贵州-黔东南-丹寨县"},
{"address":"贵州-黔南-都匀市"},
{"address":"贵州-黔南-福泉市"},
{"address":"贵州-黔南-荔波县"},
{"address":"贵州-黔南-贵定县"},
{"address":"贵州-黔南-瓮安县"},
{"address":"贵州-黔南-独山县"},
{"address":"贵州-黔南-平塘县"},
{"address":"贵州-黔南-罗甸县"},
{"address":"贵州-黔南-长顺县"},
{"address":"贵州-黔南-龙里县"},
{"address":"贵州-黔南-惠水县"},
{"address":"贵州-黔南-三都水族自治县"},
{"address":"云南-昆明-五华区"},
{"address":"云南-昆明-盘龙区"},
{"address":"云南-昆明-官渡区"},
{"address":"云南-昆明-西山区"},
{"address":"云南-昆明-东川区"},
{"address":"云南-昆明-呈贡区"},
{"address":"云南-昆明-晋宁区"},
{"address":"云南-昆明-富民县"},
{"address":"云南-昆明-宜良县"},
{"address":"云南-昆明-石林彝族自治县"},
{"address":"云南-昆明-嵩明县"},
{"address":"云南-昆明-禄劝彝族苗族自治县"},
{"address":"云南-昆明-寻甸回族彝族自治县"},
{"address":"云南-昆明-安宁市"},
{"address":"云南-曲靖-麒麟区"},
{"address":"云南-曲靖-沾益区"},
{"address":"云南-曲靖-马龙区"},
{"address":"云南-曲靖-陆良县"},
{"address":"云南-曲靖-师宗县"},
{"address":"云南-曲靖-罗平县"},
{"address":"云南-曲靖-富源县"},
{"address":"云南-曲靖-会泽县"},
{"address":"云南-曲靖-宣威市"},
{"address":"云南-玉溪-红塔区"},
{"address":"云南-玉溪-江川区"},
{"address":"云南-玉溪-通海县"},
{"address":"云南-玉溪-华宁县"},
{"address":"云南-玉溪-易门县"},
{"address":"云南-玉溪-峨山彝族自治县"},
{"address":"云南-玉溪-新平彝族傣族自治县"},
{"address":"云南-玉溪-元江哈尼族彝族傣族自治县"},
{"address":"云南-玉溪-澄江市"},
{"address":"云南-保山-隆阳区"},
{"address":"云南-保山-施甸县"},
{"address":"云南-保山-龙陵县"},
{"address":"云南-保山-昌宁县"},
{"address":"云南-保山-腾冲市"},
{"address":"云南-昭通-昭阳区"},
{"address":"云南-昭通-鲁甸县"},
{"address":"云南-昭通-巧家县"},
{"address":"云南-昭通-盐津县"},
{"address":"云南-昭通-大关县"},
{"address":"云南-昭通-永善县"},
{"address":"云南-昭通-绥江县"},
{"address":"云南-昭通-镇雄县"},
{"address":"云南-昭通-彝良县"},
{"address":"云南-昭通-威信县"},
{"address":"云南-昭通-水富市"},
{"address":"云南-丽江-古城区"},
{"address":"云南-丽江-玉龙纳西族自治县"},
{"address":"云南-丽江-永胜县"},
{"address":"云南-丽江-华坪县"},
{"address":"云南-丽江-宁蒗彝族自治县"},
{"address":"云南-普洱-思茅区"},
{"address":"云南-普洱-宁洱哈尼族彝族自治县"},
{"address":"云南-普洱-墨江哈尼族自治县"},
{"address":"云南-普洱-景东彝族自治县"},
{"address":"云南-普洱-景谷傣族彝族自治县"},
{"address":"云南-普洱-镇沅彝族哈尼族拉祜族自治县"},
{"address":"云南-普洱-江城哈尼族彝族自治县"},
{"address":"云南-普洱-孟连傣族拉祜族佤族自治县"},
{"address":"云南-普洱-澜沧拉祜族自治县"},
{"address":"云南-普洱-西盟佤族自治县"},
{"address":"云南-临沧-临翔区"},
{"address":"云南-临沧-凤庆县"},
{"address":"云南-临沧-云县"},
{"address":"云南-临沧-永德县"},
{"address":"云南-临沧-镇康县"},
{"address":"云南-临沧-双江拉祜族佤族布朗族傣族自治县"},
{"address":"云南-临沧-耿马傣族佤族自治县"},
{"address":"云南-临沧-沧源佤族自治县"},
{"address":"云南-楚雄-楚雄市"},
{"address":"云南-楚雄-禄丰市"},
{"address":"云南-楚雄-双柏县"},
{"address":"云南-楚雄-牟定县"},
{"address":"云南-楚雄-南华县"},
{"address":"云南-楚雄-姚安县"},
{"address":"云南-楚雄-大姚县"},
{"address":"云南-楚雄-永仁县"},
{"address":"云南-楚雄-元谋县"},
{"address":"云南-楚雄-武定县"},
{"address":"云南-红河-个旧市"},
{"address":"云南-红河-开远市"},
{"address":"云南-红河-蒙自市"},
{"address":"云南-红河-弥勒市"},
{"address":"云南-红河-屏边苗族自治县"},
{"address":"云南-红河-建水县"},
{"address":"云南-红河-石屏县"},
{"address":"云南-红河-泸西县"},
{"address":"云南-红河-元阳县"},
{"address":"云南-红河-红河县"},
{"address":"云南-红河-金平苗族瑶族傣族自治县"},
{"address":"云南-红河-绿春县"},
{"address":"云南-红河-河口瑶族自治县"},
{"address":"云南-文山-文山市"},
{"address":"云南-文山-砚山县"},
{"address":"云南-文山-西畴县"},
{"address":"云南-文山-麻栗坡县"},
{"address":"云南-文山-马关县"},
{"address":"云南-文山-丘北县"},
{"address":"云南-文山-广南县"},
{"address":"云南-文山-富宁县"},
{"address":"云南-西双版-景洪市"},
{"address":"云南-西双版-勐海县"},
{"address":"云南-西双版-勐腊县"},
{"address":"云南-大理-大理市"},
{"address":"云南-大理-漾濞彝族自治县"},
{"address":"云南-大理-祥云县"},
{"address":"云南-大理-宾川县"},
{"address":"云南-大理-弥渡县"},
{"address":"云南-大理-南涧彝族自治县"},
{"address":"云南-大理-巍山彝族回族自治县"},
{"address":"云南-大理-永平县"},
{"address":"云南-大理-云龙县"},
{"address":"云南-大理-洱源县"},
{"address":"云南-大理-剑川县"},
{"address":"云南-大理-鹤庆县"},
{"address":"云南-德宏-瑞丽市"},
{"address":"云南-德宏-芒市"},
{"address":"云南-德宏-梁河县"},
{"address":"云南-德宏-盈江县"},
{"address":"云南-德宏-陇川县"},
{"address":"云南-怒江-泸水市"},
{"address":"云南-怒江-福贡县"},
{"address":"云南-怒江-贡山独龙族怒族自治县"},
{"address":"云南-怒江-兰坪白族普米族自治县"},
{"address":"云南-迪庆-香格里拉市"},
{"address":"云南-迪庆-德钦县"},
{"address":"云南-迪庆-维西傈僳族自治县"},
{"address":"西藏-拉萨-城关区"},
{"address":"西藏-拉萨-堆龙德庆区"},
{"address":"西藏-拉萨-达孜区"},
{"address":"西藏-拉萨-林周县"},
{"address":"西藏-拉萨-当雄县"},
{"address":"西藏-拉萨-尼木县"},
{"address":"西藏-拉萨-曲水县"},
{"address":"西藏-拉萨-墨竹工卡县"},
{"address":"西藏-日喀则-桑珠孜区"},
{"address":"西藏-日喀则-南木林县"},
{"address":"西藏-日喀则-江孜县"},
{"address":"西藏-日喀则-定日县"},
{"address":"西藏-日喀则-萨迦县"},
{"address":"西藏-日喀则-拉孜县"},
{"address":"西藏-日喀则-昂仁县"},
{"address":"西藏-日喀则-谢通门县"},
{"address":"西藏-日喀则-白朗县"},
{"address":"西藏-日喀则-仁布县"},
{"address":"西藏-日喀则-康马县"},
{"address":"西藏-日喀则-定结县"},
{"address":"西藏-日喀则-仲巴县"},
{"address":"西藏-日喀则-亚东县"},
{"address":"西藏-日喀则-吉隆县"},
{"address":"西藏-日喀则-聂拉木县"},
{"address":"西藏-日喀则-萨嘎县"},
{"address":"西藏-日喀则-岗巴县"},
{"address":"西藏-昌都-卡若区"},
{"address":"西藏-昌都-江达县"},
{"address":"西藏-昌都-贡觉县"},
{"address":"西藏-昌都-类乌齐县"},
{"address":"西藏-昌都-丁青县"},
{"address":"西藏-昌都-察雅县"},
{"address":"西藏-昌都-八宿县"},
{"address":"西藏-昌都-左贡县"},
{"address":"西藏-昌都-芒康县"},
{"address":"西藏-昌都-洛隆县"},
{"address":"西藏-昌都-边坝县"},
{"address":"西藏-林芝-巴宜区"},
{"address":"西藏-林芝-工布江达县"},
{"address":"西藏-林芝-米林县"},
{"address":"西藏-林芝-墨脱县"},
{"address":"西藏-林芝-波密县"},
{"address":"西藏-林芝-察隅县"},
{"address":"西藏-林芝-朗县"},
{"address":"西藏-山南-乃东区"},
{"address":"西藏-山南-扎囊县"},
{"address":"西藏-山南-贡嘎县"},
{"address":"西藏-山南-桑日县"},
{"address":"西藏-山南-琼结县"},
{"address":"西藏-山南-曲松县"},
{"address":"西藏-山南-措美县"},
{"address":"西藏-山南-洛扎县"},
{"address":"西藏-山南-加查县"},
{"address":"西藏-山南-隆子县"},
{"address":"西藏-山南-错那县"},
{"address":"西藏-山南-浪卡子县"},
{"address":"西藏-那曲-色尼区"},
{"address":"西藏-那曲-嘉黎县"},
{"address":"西藏-那曲-比如县"},
{"address":"西藏-那曲-聂荣县"},
{"address":"西藏-那曲-安多县"},
{"address":"西藏-那曲-申扎县"},
{"address":"西藏-那曲-索县"},
{"address":"西藏-那曲-班戈县"},
{"address":"西藏-那曲-巴青县"},
{"address":"西藏-那曲-尼玛县"},
{"address":"西藏-那曲-双湖县"},
{"address":"西藏-阿里-普兰县"},
{"address":"西藏-阿里-札达县"},
{"address":"西藏-阿里-噶尔县"},
{"address":"西藏-阿里-日土县"},
{"address":"西藏-阿里-革吉县"},
{"address":"西藏-阿里-改则县"},
{"address":"西藏-阿里-措勤县"},
{"address":"陕西-西安-新城区"},
{"address":"陕西-西安-碑林区"},
{"address":"陕西-西安-莲湖区"},
{"address":"陕西-西安-灞桥区"},
{"address":"陕西-西安-未央区"},
{"address":"陕西-西安-雁塔区"},
{"address":"陕西-西安-阎良区"},
{"address":"陕西-西安-临潼区"},
{"address":"陕西-西安-长安区"},
{"address":"陕西-西安-高陵区"},
{"address":"陕西-西安-鄠邑区"},
{"address":"陕西-西安-蓝田县"},
{"address":"陕西-西安-周至县"},
{"address":"陕西-铜川-王益区"},
{"address":"陕西-铜川-印台区"},
{"address":"陕西-铜川-耀州区"},
{"address":"陕西-铜川-宜君县"},
{"address":"陕西-宝鸡-渭滨区"},
{"address":"陕西-宝鸡-金台区"},
{"address":"陕西-宝鸡-陈仓区"},
{"address":"陕西-宝鸡-凤翔区"},
{"address":"陕西-宝鸡-岐山县"},
{"address":"陕西-宝鸡-扶风县"},
{"address":"陕西-宝鸡-眉县"},
{"address":"陕西-宝鸡-陇县"},
{"address":"陕西-宝鸡-千阳县"},
{"address":"陕西-宝鸡-麟游县"},
{"address":"陕西-宝鸡-凤县"},
{"address":"陕西-宝鸡-太白县"},
{"address":"陕西-咸阳-秦都区"},
{"address":"陕西-咸阳-杨陵区"},
{"address":"陕西-咸阳-渭城区"},
{"address":"陕西-咸阳-三原县"},
{"address":"陕西-咸阳-泾阳县"},
{"address":"陕西-咸阳-礼泉县"},
{"address":"陕西-咸阳-永寿县"},
{"address":"陕西-咸阳-长武县"},
{"address":"陕西-咸阳-旬邑县"},
{"address":"陕西-咸阳-淳化县"},
{"address":"陕西-咸阳-武功县"},
{"address":"陕西-咸阳-兴平市"},
{"address":"陕西-咸阳-彬州市"},
{"address":"陕西-咸阳-乾县"},
{"address":"陕西-渭南-临渭区"},
{"address":"陕西-渭南-华州区"},
{"address":"陕西-渭南-潼关县"},
{"address":"陕西-渭南-大荔县"},
{"address":"陕西-渭南-合阳县"},
{"address":"陕西-渭南-澄城县"},
{"address":"陕西-渭南-蒲城县"},
{"address":"陕西-渭南-白水县"},
{"address":"陕西-渭南-富平县"},
{"address":"陕西-渭南-韩城市"},
{"address":"陕西-渭南-华阴市"},
{"address":"陕西-延安-宝塔区"},
{"address":"陕西-延安-安塞区"},
{"address":"陕西-延安-延长县"},
{"address":"陕西-延安-延川县"},
{"address":"陕西-延安-志丹县"},
{"address":"陕西-延安-吴起县"},
{"address":"陕西-延安-甘泉县"},
{"address":"陕西-延安-富县"},
{"address":"陕西-延安-洛川县"},
{"address":"陕西-延安-宜川县"},
{"address":"陕西-延安-黄龙县"},
{"address":"陕西-延安-黄陵县"},
{"address":"陕西-延安-子长市"},
{"address":"陕西-汉中-汉台区"},
{"address":"陕西-汉中-南郑区"},
{"address":"陕西-汉中-城固县"},
{"address":"陕西-汉中-洋县"},
{"address":"陕西-汉中-西乡县"},
{"address":"陕西-汉中-勉县"},
{"address":"陕西-汉中-宁强县"},
{"address":"陕西-汉中-略阳县"},
{"address":"陕西-汉中-镇巴县"},
{"address":"陕西-汉中-留坝县"},
{"address":"陕西-汉中-佛坪县"},
{"address":"陕西-榆林-榆阳区"},
{"address":"陕西-榆林-横山区"},
{"address":"陕西-榆林-府谷县"},
{"address":"陕西-榆林-靖边县"},
{"address":"陕西-榆林-定边县"},
{"address":"陕西-榆林-绥德县"},
{"address":"陕西-榆林-米脂县"},
{"address":"陕西-榆林-佳县"},
{"address":"陕西-榆林-吴堡县"},
{"address":"陕西-榆林-清涧县"},
{"address":"陕西-榆林-子洲县"},
{"address":"陕西-榆林-神木市"},
{"address":"陕西-安康-汉滨区"},
{"address":"陕西-安康-汉阴县"},
{"address":"陕西-安康-石泉县"},
{"address":"陕西-安康-宁陕县"},
{"address":"陕西-安康-紫阳县"},
{"address":"陕西-安康-岚皋县"},
{"address":"陕西-安康-平利县"},
{"address":"陕西-安康-镇坪县"},
{"address":"陕西-安康-白河县"},
{"address":"陕西-安康-旬阳市"},
{"address":"陕西-商洛-商州区"},
{"address":"陕西-商洛-洛南县"},
{"address":"陕西-商洛-丹凤县"},
{"address":"陕西-商洛-商南县"},
{"address":"陕西-商洛-山阳县"},
{"address":"陕西-商洛-镇安县"},
{"address":"陕西-商洛-柞水县"},
{"address":"甘肃-兰州-城关区"},
{"address":"甘肃-兰州-七里河区"},
{"address":"甘肃-兰州-西固区"},
{"address":"甘肃-兰州-安宁区"},
{"address":"甘肃-兰州-红古区"},
{"address":"甘肃-兰州-永登县"},
{"address":"甘肃-兰州-皋兰县"},
{"address":"甘肃-兰州-榆中县"},
{"address":"甘肃-嘉峪关-嘉峪关市"},
{"address":"甘肃-金昌-金川区"},
{"address":"甘肃-金昌-永昌县"},
{"address":"甘肃-白银-白银区"},
{"address":"甘肃-白银-平川区"},
{"address":"甘肃-白银-靖远县"},
{"address":"甘肃-白银-会宁县"},
{"address":"甘肃-白银-景泰县"},
{"address":"甘肃-天水-秦州区"},
{"address":"甘肃-天水-麦积区"},
{"address":"甘肃-天水-清水县"},
{"address":"甘肃-天水-秦安县"},
{"address":"甘肃-天水-甘谷县"},
{"address":"甘肃-天水-武山县"},
{"address":"甘肃-天水-张家川回族自治县"},
{"address":"甘肃-武威-凉州区"},
{"address":"甘肃-武威-民勤县"},
{"address":"甘肃-武威-古浪县"},
{"address":"甘肃-武威-天祝藏族自治县"},
{"address":"甘肃-张掖-甘州区"},
{"address":"甘肃-张掖-肃南裕固族自治县"},
{"address":"甘肃-张掖-民乐县"},
{"address":"甘肃-张掖-临泽县"},
{"address":"甘肃-张掖-高台县"},
{"address":"甘肃-张掖-山丹县"},
{"address":"甘肃-平凉-崆峒区"},
{"address":"甘肃-平凉-泾川县"},
{"address":"甘肃-平凉-灵台县"},
{"address":"甘肃-平凉-崇信县"},
{"address":"甘肃-平凉-庄浪县"},
{"address":"甘肃-平凉-静宁县"},
{"address":"甘肃-平凉-华亭市"},
{"address":"甘肃-酒泉-肃州区"},
{"address":"甘肃-酒泉-金塔县"},
{"address":"甘肃-酒泉-瓜州县"},
{"address":"甘肃-酒泉-肃北蒙古族自治县"},
{"address":"甘肃-酒泉-阿克塞哈萨克族自治县"},
{"address":"甘肃-酒泉-玉门市"},
{"address":"甘肃-酒泉-敦煌市"},
{"address":"甘肃-庆阳-西峰区"},
{"address":"甘肃-庆阳-庆城县"},
{"address":"甘肃-庆阳-环县"},
{"address":"甘肃-庆阳-华池县"},
{"address":"甘肃-庆阳-合水县"},
{"address":"甘肃-庆阳-正宁县"},
{"address":"甘肃-庆阳-宁县"},
{"address":"甘肃-庆阳-镇原县"},
{"address":"甘肃-定西-安定区"},
{"address":"甘肃-定西-通渭县"},
{"address":"甘肃-定西-陇西县"},
{"address":"甘肃-定西-渭源县"},
{"address":"甘肃-定西-临洮县"},
{"address":"甘肃-定西-漳县"},
{"address":"甘肃-定西-岷县"},
{"address":"甘肃-陇南-武都区"},
{"address":"甘肃-陇南-成县"},
{"address":"甘肃-陇南-文县"},
{"address":"甘肃-陇南-宕昌县"},
{"address":"甘肃-陇南-康县"},
{"address":"甘肃-陇南-西和县"},
{"address":"甘肃-陇南-礼县"},
{"address":"甘肃-陇南-徽县"},
{"address":"甘肃-陇南-两当县"},
{"address":"甘肃-临夏-临夏市"},
{"address":"甘肃-临夏-临夏县"},
{"address":"甘肃-临夏-康乐县"},
{"address":"甘肃-临夏-永靖县"},
{"address":"甘肃-临夏-广河县"},
{"address":"甘肃-临夏-和政县"},
{"address":"甘肃-临夏-东乡族自治县"},
{"address":"甘肃-临夏-积石山保安族东乡族撒拉族自治县"},
{"address":"甘肃-甘南-合作市"},
{"address":"甘肃-甘南-临潭县"},
{"address":"甘肃-甘南-卓尼县"},
{"address":"甘肃-甘南-舟曲县"},
{"address":"甘肃-甘南-迭部县"},
{"address":"甘肃-甘南-玛曲县"},
{"address":"甘肃-甘南-碌曲县"},
{"address":"甘肃-甘南-夏河县"},
{"address":"青海-西宁-西宁市"},
{"address":"青海-西宁-城东区"},
{"address":"青海-西宁-城中区"},
{"address":"青海-西宁-城西区"},
{"address":"青海-西宁-城北区"},
{"address":"青海-西宁-湟中区"},
{"address":"青海-西宁-大通回族土族自治县"},
{"address":"青海-西宁-湟源县"},
{"address":"青海-海东-乐都区"},
{"address":"青海-海东-平安区"},
{"address":"青海-海东-民和回族土族自治县"},
{"address":"青海-海东-互助土族自治县"},
{"address":"青海-海东-化隆回族自治县"},
{"address":"青海-海东-循化撒拉族自治县"},
{"address":"青海-海北-门源回族自治县"},
{"address":"青海-海北-祁连县"},
{"address":"青海-海北-海晏县"},
{"address":"青海-海北-刚察县"},
{"address":"青海-黄南-同仁市"},
{"address":"青海-黄南-尖扎县"},
{"address":"青海-黄南-泽库县"},
{"address":"青海-黄南-河南蒙古族自治县"},
{"address":"青海-海南-共和县"},
{"address":"青海-海南-同德县"},
{"address":"青海-海南-贵德县"},
{"address":"青海-海南-兴海县"},
{"address":"青海-海南-贵南县"},
{"address":"青海-果洛-玛沁县"},
{"address":"青海-果洛-班玛县"},
{"address":"青海-果洛-甘德县"},
{"address":"青海-果洛-达日县"},
{"address":"青海-果洛-久治县"},
{"address":"青海-果洛-玛多县"},
{"address":"青海-玉树-玉树市"},
{"address":"青海-玉树-杂多县"},
{"address":"青海-玉树-称多县"},
{"address":"青海-玉树-治多县"},
{"address":"青海-玉树-囊谦县"},
{"address":"青海-玉树-曲麻莱县"},
{"address":"青海-海西-格尔木市"},
{"address":"青海-海西-德令哈市"},
{"address":"青海-海西-茫崖市"},
{"address":"青海-海西-乌兰县"},
{"address":"青海-海西-都兰县"},
{"address":"青海-海西-天峻县"},
{"address":"青海-海西-大柴旦行政委员会"},
{"address":"宁夏-银川-兴庆区"},
{"address":"宁夏-银川-西夏区"},
{"address":"宁夏-银川-金凤区"},
{"address":"宁夏-银川-永宁县"},
{"address":"宁夏-银川-贺兰县"},
{"address":"宁夏-银川-灵武市"},
{"address":"宁夏-石嘴山-大武口区"},
{"address":"宁夏-石嘴山-惠农区"},
{"address":"宁夏-石嘴山-平罗县"},
{"address":"宁夏-吴忠-利通区"},
{"address":"宁夏-吴忠-红寺堡区"},
{"address":"宁夏-吴忠-盐池县"},
{"address":"宁夏-吴忠-同心县"},
{"address":"宁夏-吴忠-青铜峡市"},
{"address":"宁夏-固原-原州区"},
{"address":"宁夏-固原-西吉县"},
{"address":"宁夏-固原-隆德县"},
{"address":"宁夏-固原-泾源县"},
{"address":"宁夏-固原-彭阳县"},
{"address":"宁夏-中卫-沙坡头区"},
{"address":"宁夏-中卫-中宁县"},
{"address":"宁夏-中卫-海原县"},
{"address":"新疆-乌鲁木齐-天山区"},
{"address":"新疆-乌鲁木齐-沙依巴克区"},
{"address":"新疆-乌鲁木齐-新市区"},
{"address":"新疆-乌鲁木齐-水磨沟区"},
{"address":"新疆-乌鲁木齐-头屯河区"},
{"address":"新疆-乌鲁木齐-达坂城区"},
{"address":"新疆-乌鲁木齐-米东区"},
{"address":"新疆-乌鲁木齐-乌鲁木齐县"},
{"address":"新疆-克拉玛依-独山子区"},
{"address":"新疆-克拉玛依-克拉玛依区"},
{"address":"新疆-克拉玛依-白碱滩区"},
{"address":"新疆-克拉玛依-乌尔禾区"},
{"address":"新疆-吐鲁番-高昌区"},
{"address":"新疆-吐鲁番-鄯善县"},
{"address":"新疆-吐鲁番-托克逊县"},
{"address":"新疆-哈密-伊州区"},
{"address":"新疆-哈密-萨克自治县"},
{"address":"新疆-哈密-伊吾县"},
{"address":"新疆-昌吉-昌吉市"},
{"address":"新疆-昌吉-阜康市"},
{"address":"新疆-昌吉-呼图壁县"},
{"address":"新疆-昌吉-玛纳斯县"},
{"address":"新疆-昌吉-奇台县"},
{"address":"新疆-昌吉-吉木萨尔县"},
{"address":"新疆-昌吉-木垒哈萨克自治县"},
{"address":"新疆-博尔塔-博乐市"},
{"address":"新疆-博尔塔-阿拉山口市"},
{"address":"新疆-博尔塔-精河县"},
{"address":"新疆-博尔塔-温泉县"},
{"address":"新疆-巴音郭楞-库尔勒市"},
{"address":"新疆-巴音郭楞-轮台县"},
{"address":"新疆-巴音郭楞-尉犁县"},
{"address":"新疆-巴音郭楞-若羌县"},
{"address":"新疆-巴音郭楞-且末县"},
{"address":"新疆-巴音郭楞-焉耆回族自治县"},
{"address":"新疆-巴音郭楞-和静县"},
{"address":"新疆-巴音郭楞-和硕县"},
{"address":"新疆-巴音郭楞-博湖县"},
{"address":"新疆-阿克苏-阿克苏市"},
{"address":"新疆-阿克苏-库车市"},
{"address":"新疆-阿克苏-温宿县"},
{"address":"新疆-阿克苏-沙雅县"},
{"address":"新疆-阿克苏-新和县"},
{"address":"新疆-阿克苏-拜城县"},
{"address":"新疆-阿克苏-乌什县"},
{"address":"新疆-阿克苏-阿瓦提县"},
{"address":"新疆-阿克苏-柯坪县"},
{"address":"新疆-克孜勒苏-阿图什市"},
{"address":"新疆-克孜勒苏-阿克陶县"},
{"address":"新疆-克孜勒苏-阿合奇县"},
{"address":"新疆-克孜勒苏-乌恰县"},
{"address":"新疆-喀什-喀什市"},
{"address":"新疆-喀什-疏附县"},
{"address":"新疆-喀什-疏勒县"},
{"address":"新疆-喀什-英吉沙县"},
{"address":"新疆-喀什-泽普县"},
{"address":"新疆-喀什-莎车县"},
{"address":"新疆-喀什-叶城县"},
{"address":"新疆-喀什-麦盖提县"},
{"address":"新疆-喀什-岳普湖县"},
{"address":"新疆-喀什-伽师县"},
{"address":"新疆-喀什-巴楚县"},
{"address":"新疆-喀什-塔什库尔干塔吉克自治县"},
{"address":"新疆-和田-和田市"},
{"address":"新疆-和田-和田县"},
{"address":"新疆-和田-墨玉县"},
{"address":"新疆-和田-皮山县"},
{"address":"新疆-和田-洛浦县"},
{"address":"新疆-和田-策勒县"},
{"address":"新疆-和田-于田县"},
{"address":"新疆-和田-民丰县"},
{"address":"新疆-伊犁-伊宁市"},
{"address":"新疆-伊犁-奎屯市"},
{"address":"新疆-伊犁-霍尔果斯市"},
{"address":"新疆-伊犁-伊宁县"},
{"address":"新疆-伊犁-察布查尔锡伯自治县"},
{"address":"新疆-伊犁-霍城县"},
{"address":"新疆-伊犁-巩留县"},
{"address":"新疆-伊犁-新源县"},
{"address":"新疆-伊犁-昭苏县"},
{"address":"新疆-伊犁-特克斯县"},
{"address":"新疆-伊犁-尼勒克县"},
{"address":"新疆-塔城-塔城市"},
{"address":"新疆-塔城-乌苏市"},
{"address":"新疆-塔城-沙湾市"},
{"address":"新疆-塔城-额敏县"},
{"address":"新疆-塔城-托里县"},
{"address":"新疆-塔城-裕民县"},
{"address":"新疆-塔城-和布克赛尔蒙古自治县"},
{"address":"新疆-阿勒泰-阿勒泰市"},
{"address":"新疆-阿勒泰-布尔津县"},
{"address":"新疆-阿勒泰-富蕴县"},
{"address":"新疆-阿勒泰-福海县"},
{"address":"新疆-阿勒泰-哈巴河县"},
{"address":"新疆-阿勒泰-青河县"},
{"address":"新疆-阿勒泰-吉木乃县"},
{"address":"新疆-石河子-石河子市"},
{"address":"新疆-阿拉尔-阿拉尔市"},
{"address":"新疆-图木舒克-图木舒克市"},
{"address":"新疆-五家渠-五家渠市"},
{"address":"新疆-北屯-北屯市"},
{"address":"新疆-铁门关-铁门关市"},
{"address":"新疆-双河-双河市"},
{"address":"新疆-可克达拉-可克达拉市"},
{"address":"新疆-昆玉-昆玉市"},
{"address":"新疆-胡杨河-胡杨河市"},
{"address":"新疆-新星-新星市"}
])

const init = ()=>{
    userInfo.value=props.userInfo
    updData.value.CNAddress=Caddress.value
}
init()

const initWebsocket=()=>{
    let socket;
    const username=userInfo.value.username
    const socketUrl="wss://www.xiaolatech.cn:8081/xlkj/ws/tShopInfo/"+username

    if(socket!=null){
        socket.close();
        socket=null;
    }

    socket = new WebSocket(socketUrl);
    //打开事件
    socket.onopen = function() {
        console.log("websocket已打开");
    };
    //获得消息事件
    socket.onmessage = function(msg) {
        console.log(msg.data);
        shopList(shopQueryDTO.value)
        //发现消息进入,开始处理前端触发逻辑
    };
    //关闭事件
    socket.onclose = function() {
        console.log("websocket已关闭");
    };
    //发生了错误事件
    socket.onerror = function() {
        console.log("websocket发生了错误");
    }
}
initWebsocket()
</script>

<style lang="scss" scoped>
.page-container {
    min-height: 100%;
    box-sizing: border-box;

    .header {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .el-form-item{
        float: left;
        display: flex;
        flex-direction: row;
    }

    .inputBar{
        width: 120px;
    }

    .button{
        float: right;
        margin: 5px;
        /* display: flex;
        flex-direction: row-reverse; */
    }
}

#search {
    width:  100%;
    height: 100px
}
.el-link {
    margin-right: 10px;
}
</style>