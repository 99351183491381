<template>
    <el-dialog v-model="visibleDrawer" title="登记订单" width="700px" @opened="returnData()">
        <el-table :data="tableData.orderProfitTotalList" height="250" border style="width: 100%">
            <el-table-column prop="shopName" label="商家" width="180"></el-table-column>
            <el-table-column prop="count" label="订单数" width="180"></el-table-column>
            <el-table-column prop="profitMoneyView" label="毛利"></el-table-column>
        </el-table>
        <el-row :gutter="20" style="margin-top: 30px;">
            <el-col :span="4"><div class="grid-content bg-purple">总订单数: {{ tableData.countTotal }}</div></el-col>
            <el-col :span="15"><div class="grid-content bg-purple">总毛利: {{ tableData.profitTotal }}￥</div></el-col>
        </el-row>
    </el-dialog>
</template>
<script setup>
import { ref,defineProps } from 'vue'
import {
    queryProfitTotal
} from '@/api/order.js';

const visibleDrawer = ref(false)

const open=()=>{
    visibleDrawer.value=true
}

const props = defineProps ({
  queryOrderData : String
})

const tableData=ref({
    profitTotal:'',
    countTotal:'',
    orderProfitTotalList:[{
        shopName:'',
        count:'',
        profitMoneyView:''
    }]
})
const profitQuery=ref({
    startTime:'',
    endTime:'',
    type:3
})

const returnData = async()=>{
    profitQuery.value.startTime = props.queryOrderData.startDeliveryTime;
    profitQuery.value.endTime = props.queryOrderData.endDeliveryTime;
    profitQuery.value.shopName = props.queryOrderData.shopName;
    profitQuery.value.type = 3;
    
    let result =await queryProfitTotal(profitQuery.value);
    tableData.value=result.data
    let orderProfitTotalList=tableData.value.orderProfitTotalList
    orderProfitTotalList.forEach(function(item){
        item.profitMoneyView=item.profitMoneyView.toFixed(2)
        item.profitMoneyView+='￥'
    })
    tableData.value.orderProfitTotalList=orderProfitTotalList
    tableData.value.profitTotal=tableData.value.profitTotal.toFixed(2)
}

defineExpose({open})
</script>