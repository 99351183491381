import request from '@/utils/request.js'
export const shopListService=(shopQueryDTO)=>{
    // const tokenStore=useTokenStore();
    return request.post('/api/mtshop/shopList',shopQueryDTO)
}

export const shopAppService=(MTShopInfo)=>{
    return request.post('/api/mtshop/shopCheck',MTShopInfo)
}

export const shopOpeService=(MTShopInfo)=>{
    return request.post('/api/mtshop/shopOperate',MTShopInfo)
}

export const shopUpdService=(MTShopInfo)=>{
    return request.post('/api/mtshop/shopUpdate',MTShopInfo)
}

export const JDstoreInfo=(storeCode,accountName)=>{
    return request.get('/api/mtshop/JDstoreInfo?storeCode='+storeCode+"&accountName="+accountName)
}

export const shopInfoService=(id)=>{
    return request.get('/api/mtshop/shopInfoEcho?id='+id)
}

export const checkInfoService=(id)=>{
    return request.get('/api/mtshop/checkInfoEcho?id='+id)
}

export const shopDelService=(id)=>{
    return request.get('/api/mtshop/deleteShop?id='+id)
}

export const shopAccount=()=>{
    return request.get('/api/mtshop/shopAccount')
}
export const mapMsgService=(mapMsgQuery)=>{
    return request.get('/api/addressMsg?keyword='+mapMsgQuery.keyword+'&city='+mapMsgQuery.city+'&total='+mapMsgQuery.total)
}