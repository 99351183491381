<template>
    <div class="box">
    <el-dialog v-model="visibleDrawer" title="京东登记订单" width="700px">
        <el-form ref="form" :model="checkInfo" label-width="auto" style="max-width: 700px">
<!--          <el-form-item label="所属平台">-->
<!--            <el-select v-model="checkInfo.type">-->
<!--              <el-option label="京东" value="1"></el-option>-->
<!--              <el-option label="淘宝" value="2"></el-option>-->
<!--              <el-option label="美团" value="3"></el-option>-->
<!--            </el-select>-->
<!--          </el-form-item>-->
            <el-form-item label="订单号">
                <el-input v-model="checkInfo.orderId" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="商家名称">
                <el-input v-model="checkInfo.venderId" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="订单状态">
                <el-select v-model="checkInfo.orderStatus">
                <el-option label="待处理" value="41000"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="下单时间">
                <el-input v-model="checkInfo.orderPreEndDeliveryTime" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="预计最晚送达时间">
              <el-input v-model="checkInfo.orderStartTime" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="客户名">
                <el-input v-model="checkInfo.buyerFullName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="详细地址">
                <el-input v-model="checkInfo.buyerFullAddress" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="备注">
                <el-input type="textarea" v-model="checkInfo.orderBuyerRemark" :rows="5"></el-input>
            </el-form-item>
            <el-form-item label="用户应付金额">
                <el-input v-model="checkInfo.orderBuyerPayableMoney" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="本单预计收入">
                <el-input v-model="checkInfo.settlementAmount" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="sku名称">
                <el-input v-model="checkInfo.skuName" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="skuId">
                <el-input v-model="checkInfo.skuId" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="sku商品价格">
                <el-input v-model="checkInfo.skuJdPrice" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="商品数量">
                <el-input v-model="checkInfo.skuCount" placeholder=""></el-input>
            </el-form-item>
        </el-form>
        <template #footer>
            <div class="dialog-footer">
                <el-button type="primary" @click="add()">
                登记
                </el-button>
            </div>
        </template>
    </el-dialog>
    </div>
</template>
<script setup>
import { ref,defineProps } from 'vue'
import {
    addOfflineOrder
} from '@/api/order.js';
import { ElMessage } from 'element-plus';
const visibleDrawer = ref(false)

const form=ref(null)

const open=()=>{
    visibleDrawer.value=true
}
defineExpose({open})

const returnData=async()=>{
  console.log("加载数据")
}

const checkInfo=ref({
    orderId:'',
    venderId:'',
    orderStatus:'',
    orderStartTime:'',
    orderPreEndDeliveryTime:'',
    buyerFullName:'',
    buyerFullAddress:'',
    orderBuyerRemark:'',
    orderBuyerPayableMoney:'',
    settlementAmount:'',
    skuName:'',
    skuId:'',
    skuJdPrice:'',
    skuCount:'',
    type:'1'
})


const add=async()=>{
    let result = await addOfflineOrder(checkInfo.value)
    if(result.code=='200'){
        ElMessage.success("添加成功")
        visibleDrawer.value=false
        // location.reload()
        clear()
    }
}
const clear=()=>{
    checkInfo.value.orderId='';
    checkInfo.value.venderId='';
    checkInfo.value.orderStatus='';
    checkInfo.value.orderStartTime='';
    checkInfo.value.orderPreEndDeliveryTime='';
    checkInfo.value.buyerFullName='';
    checkInfo.value.buyerFullAddress='';
    checkInfo.value.orderBuyerRemark='';
    checkInfo.value.orderBuyerPayableMoney='';
    checkInfo.value.settlementAmount='';
    checkInfo.value.skuName='';
    checkInfo.value.skuId='';
    checkInfo.value.skuJdPrice='';
    checkInfo.value.skuCount='';
};

</script>