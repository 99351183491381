<template>
    <div class="box">
        <el-dialog v-model="visibleDrawer" title="修改账号信息" width="700px" @opened="echoData()" @closed="close()">
            <el-form :model="accountData" label-width="auto" style="max-width: 600px">
                <el-form-item label="账户id">
                    <el-input v-model="accountData.venderId" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="appKey" >
                    <el-input v-model="accountData.appKey" :disabled="accountData.appKey!=''"></el-input>
                </el-form-item>
                <el-form-item label="appSecret" >
                    <el-input v-model="accountData.appSecret" :disabled="accountData.appKey!=''"></el-input>
                </el-form-item>
                <el-form-item label="appToken" >
                    <el-input v-model="accountData.token" :disabled="accountData.appKey!=''"></el-input>
                </el-form-item>
                <el-form-item label="企业微信机器人">
                    <el-input v-model="accountData.robotUrl"></el-input>
                </el-form-item>
                <el-form-item label="账号名称" >
                    <el-input v-model="accountData.remark"></el-input>
                </el-form-item>
                <el-form-item label="账号真实名称" >
                    <el-input v-model="accountData.jdRename"></el-input>
                </el-form-item>
                <el-form-item label="跳转cookie" >
                    <el-input v-model="accountData.cookies" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="京东到家cookie" >
                    <el-input v-model="accountData.jdapiCookie"></el-input>
                </el-form-item>
                <el-form-item label="序号" >
                    <el-input v-model="accountData.sort"></el-input>
                </el-form-item>
                <el-form-item label="运营区域" >
                    <el-select v-model="accountData.area" style="width: 195px;">
                        <el-option label="南区" value="1"></el-option>
                        <el-option label="北区" value="2"></el-option>
                        <el-option label="三区" value="3"></el-option>
                        <el-option label="四区" value="4"></el-option>
                    </el-select>
                </el-form-item>
              <el-form-item label="是否显示" >
                <el-select v-model="accountData.hideFlag" style="width: 195px;">
                  <el-option label="显示" value="1"></el-option>
                  <el-option label="隐藏" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="update()" :disabled=addButtom>
                    修改
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref,defineProps } from 'vue'
import { ElMessage } from 'element-plus';
import { 
    updateAccount
} from '@/api/plugin';

const selectedOptions = ref([])

const props = defineProps ({
    accountData : String
})
const accountData=ref({
    venderId: '',
    appKey: '',
    appSecret: '',
    token: '',
    robotUrl: '',
    remark: '',
    cookies: '',
    jdapiCookie: '',
    jdRename: '',
    sort: '',
    area: '',
    hideFlag: ''
})

const echoData=async()=>{
    accountData.value=props.accountData
    if(accountData.value.area==1){
        accountData.value.area="南区"
    }else if(accountData.value.area==2){
        accountData.value.area="北区"
    }else if(accountData.value.area==3){
      accountData.value.area="三区"
    }else if(accountData.value.area==4){
      accountData.value.area="四区"
    }

    if(accountData.value.hideFlag==1){
      accountData.value.hideFlag="显示"
    }else if(accountData.value.hideFlag==0){
      accountData.value.hideFlag="隐藏"
    }
}

const update=async()=>{
    if(accountData.value.area=="南区"){
        accountData.value.area=1
    }else if(accountData.value.area=="北区"){
        accountData.value.area=2
    }else if(accountData.value.area=="三区"){
        accountData.value.area=3
    }else if(accountData.value.area=="四区"){
        accountData.value.area=4
    }

   if(accountData.value.hideFlag=="显示"){
     accountData.value.hideFlag=1
   }else if(accountData.value.hideFlag=="隐藏"){
     accountData.value.hideFlag=0
   }
    const result=await updateAccount(accountData.value)
    if(result.code==200){
        visibleDrawer.value=false
        location.reload()
    }
}

const close=async()=>{
    if(accountData.value.area=="南区"){
        accountData.value.area=1
    }else if(accountData.value.area=="北区"){
        accountData.value.area=2
    }else if(accountData.value.area=="三区"){
      accountData.value.area=3
    }else if(accountData.value.area=="四区"){
      accountData.value.area=4
    }

    if(accountData.value.hideFlag=="显示"){
      accountData.value.hideFlag=1
    }else if(accountData.value.hideFlag=="隐藏"){
      accountData.value.hideFlag=0
    }
}

//控制抽屉是否显示
const visibleDrawer = ref(false)
const open=()=>{
    visibleDrawer.value=true
}
defineExpose({open})
</script>