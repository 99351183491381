<template>
    <div class="box">
        <el-dialog v-model="visibleDrawer" title="详情资质" width="700px" @opened="shopInfo()" @closed="clear()">
            <el-form :model="TShopInfo" label-width="auto" style="max-width: 600px">
                <el-form-item label="信用代码" >
                    <el-input v-model="TShopInfo.creditCode" placeholder="请输入信用代码"></el-input>
                </el-form-item>
                <el-form-item label="执照名" >
                    <el-input v-model="TShopInfo.shopName" placeholder="请输入执照名"></el-input>
                </el-form-item>
                <el-form-item label="法人名" >
                    <el-input v-model="TShopInfo.corporate" placeholder="请输入法人名"></el-input>
                </el-form-item>
                <el-form-item label="门店地址" >
                    <el-input v-model="TShopInfo.shopAddress" placeholder="请输入门店地址"></el-input>
                </el-form-item>
                <el-form-item label="地区">
                    <el-select v-model="TShopInfo.shopArea" filterable placeholder="请选择">
                        <el-option
                        v-for="item in CNAddress"
                        :key="item.id"
                        :label="item.address"
                        :value="item.address">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="法人联系电话" >
                    <el-input v-model="TShopInfo.corPhone" placeholder="请输入电话"></el-input>
                </el-form-item>
                <el-form-item label="招商日期" >
                    <el-date-picker
                        v-model="TShopInfo.createDate"
                        type="datetime"
                        placeholder="选择日期"
                        format="YYYY/MM/DD"
                        style="width: 570px;"
                    />
                </el-form-item>
                <el-form-item label="手持协议" >
                    <el-checkbox-group v-model="TShopInfo.protocolList">
                        <el-checkbox label="营业执照"></el-checkbox>
                        <el-checkbox label="身份证正反面"></el-checkbox>
                        <el-checkbox label="协议照片"></el-checkbox>
                        <el-checkbox label="门头照片"></el-checkbox>
                        <el-checkbox label="店内照片"></el-checkbox>
                        <el-checkbox label="手持身份证照片"></el-checkbox>
                        <el-checkbox label="视频资料"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="招商人">
                    <el-input v-model="TShopInfo.merchants" placeholder="请输入招商人" @keyup.enter="shopUpd"></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button @click="clear()">清空</el-button>
                    <el-button type="primary" @click="shopUpd()">
                    更新
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
import { ref,defineProps } from 'vue'
import { ElMessage } from 'element-plus';
import { 
    shopInfoService,
    shopUpdService
 } from '@/api/mtshop.js';


//控制抽屉是否显示
const visibleDrawer = ref(false)

const selectedOptions = ref([])


const open=()=>{
    visibleDrawer.value=true
}

const props = defineProps ({
    updData : String
})

const CNAddress=ref([])

const updData=ref({
    updId:'',
    departCode:''
})

defineExpose({open})
const TShopInfo = ref({
    id:'',
    creditCode: '',
    shopName: '',
    shopAddress: '',
    corporate: '',
    corPhone: '',
    shopArea: '',
    createDate: '',
    shopAccount: '',
    shopCode: '',
    auditing: '',
    carrier: '',
    operational: '',
    openStatu: '',
    protocol:'',
    protocolList: []
})

const clear=()=>{
    TShopInfo.value.creditCode=''
    TShopInfo.value.shopName=''
    TShopInfo.value.shopAddress=''
    TShopInfo.value.corporate=''
    TShopInfo.value.corPhone=''
    selectedOptions.value=[]
    TShopInfo.value.shopArea=''
    TShopInfo.value.createDate=''
    TShopInfo.value.merchants=''
    TShopInfo.value.protocol=''
    TShopInfo.value.protocolList=[]
}

const shopInfo = async()=>{
    updData.value=props.updData
    let result=await shopInfoService(updData.value.updId)
    CNAddress.value=props.updData.CNAddress
    TShopInfo.value=result.data;
    if(result.data.protocolList==null||result.data.protocolList==""){
        TShopInfo.value.protocolList=[]
        TShopInfo.value.protocol=''
    }
}

const shopUpd= async()=>{
    TShopInfo.value.createDate=formatDate(TShopInfo.value.createDate);
    console.log(TShopInfo.value.createDate)
    let result=await shopUpdService(TShopInfo.value)
    console.log(result)
    if(result.code=="200"){
        visibleDrawer.value=false
        ElMessage.success("补充成功")
    }
}

const formatDate = (dateString) => {
    var date = new Date(dateString);
    var year = date.getFullYear();

    // JavaScript 月份是从 0 开始的，所以需要加 1
    var month = ("0" + (date.getMonth() + 1)).slice(-2);

    // 获取日期，如果小于 10 则前面补 0
    var day = ("0" + date.getDate()).slice(-2);

    // 拼接成目标格式
    var string=year + "-" + month + "-" + day;
    return string
}
</script>

<style lang="scss" scoped>
.box {
    :deep(){
        .el-dialog__header {
            line-height: 1px;
            text-align: left;
            height: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 30px;
        }
    }
}

.avatar-uploader {
    :deep() {
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
        }

        .el-upload {
            border: 1px dashed var(--el-border-color);
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
            transition: var(--el-transition-duration-fast);
        }

        .el-upload:hover {
            border-color: var(--el-color-primary);
        }

        .el-icon.avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            text-align: center;
        }
    }
}
.el-form{
    .el-input{
        width: 570px;
    }
    .el-select{
        width: 570px;
    }
}
</style>