<template>
    <div class="box">
        <el-dialog v-model="visibleDrawer" title="订单登记信息重置" width="700px">
            <el-form :model="TShopInfo" label-width="auto" style="max-width: 600px">
                <el-form-item label="重置订单号">
                    <el-input v-model="TShopInfo.orderCode" placeholder="请输入需要重置的错误订单号" clearable></el-input>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="dialog-footer">
                    <el-button type="primary" @click="checkConfirm(row)" :disabled=confirmButton>
                        确认
                    </el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script setup>
    import { ref, defineProps } from 'vue'
    import { ElMessage, ElMessageBox } from 'element-plus';
    import {
        delErrOrder
    } from '@/api/order.js';


    //控制抽屉是否显示
    const visibleDrawer = ref(false)


    const open = () => {
        visibleDrawer.value = true
    }


    const confirmButton = ref(false)

    const checkOrderDel = async () => {
        try {
            confirmButton.value = true
            const result = await delErrOrder(TShopInfo.value);
            if (result.code == "200") {
                visibleDrawer.value = false
                confirmButton.value = false
                ElMessage.success("删除成功")
                clear()
            }
        } catch (error) {
            confirmButton.value = false
        }
    }

    const clear = () => {
        TShopInfo.value.orderCode = ''
    }

    defineExpose({ open })
    const TShopInfo = ref({
        orderCode: '',
    })

    const checkConfirm = () => {
        ElMessageBox.confirm('这将重置该订单登记信息，确认要这么做吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            checkOrderDel()
        })
    }



</script>

<style lang="scss" scoped>
    .box {
        :deep() {
            .el-dialog__header {
                line-height: 1px;
                text-align: left;
                height: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid #ccc;
                margin-bottom: 30px;
            }
        }
    }

    .avatar-uploader {
        :deep() {
            .avatar {
                width: 178px;
                height: 178px;
                display: block;
            }

            .el-upload {
                border: 1px dashed var(--el-border-color);
                border-radius: 6px;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                transition: var(--el-transition-duration-fast);
            }

            .el-upload:hover {
                border-color: var(--el-color-primary);
            }

            .el-icon.avatar-uploader-icon {
                font-size: 28px;
                color: #8c939d;
                width: 178px;
                height: 178px;
                text-align: center;
            }
        }
    }

    .el-form {
        .el-input {
            width: 570px;
        }

        .el-select {
            width: 570px;
        }
    }
</style>